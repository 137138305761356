<script setup>
  import gsap from 'gsap';

  // --
  const { events } = defineProps(['events']);

  const bars = ref();

  const emit = defineEmits(['eventSelect']);
  const index = ref(0);

  // --
  function select(i, force) {
    if (index.value === i && !force) return;

    gsap.killTweensOf(bars.value[index.value]);
    bars.value[index.value].style.transform = '';

    index.value = i;

    gsap.fromTo(
      bars.value[index.value],
      { scaleX: 0.0 },
      {
        duration: 6.0,
        scaleX: 1.0,
        force3D: true,
        ease: 'none',
        onComplete: () => {
          bars.value[index.value].style.transform = '';
          select(index.value < bars.value.length - 1 ? index.value + 1 : 0);
        },
      },
    );
  }

  watch(index, () => {
    emit('eventSelect', index.value);
  });

  // --
  onMounted(() => {
    select(0, true);
  });

  onBeforeUnmount(() => {
    gsap.killTweensOf(bars.value[index.value]);
  });

  // --

  const container = ref();

  useTelescope(container, {
    visible: () => select(index.value, true),
    hidden: () => gsap.killTweensOf(bars.value[index.value]),
  });

  // --

  defineExpose({ select });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="container">
    <span v-for="i in events.length" @click="select(i - 1)"
      ><span ref="bars" class="progress" :class="{ active: i - 1 < index }"
    /></span>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    width: 100%;
    height: px(2);

    display: flex;
    gap: px(4);

    z-index: 1;

    span {
      position: relative;

      background-color: $white-40;
      width: 100%;

      cursor: pointer;
      @include hitbox(px(2), 2rem);

      @include fade('background-color', 0.19s);

      @include hover {
        background-color: $white;
        @include fade('background-color', 0.12s);

        .progress {
          background-color: $white;
        }
      }

      .progress {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $red;

        transform: scale3d(0, 1, 1);
        transform-origin: 0 50%;
        &.active {
          transform: scale3d(1, 1, 1) !important;
        }
      }
    }
  }
</style>
