<script setup>
  import VueSelect from 'vue-select';

  // --

  const { items, small, initial } = defineProps({ items: Array, small: Boolean, initial: String });

  const selected = ref(items.find((x) => x.value === initial));

  const emit = defineEmits(['select']);

  // --

  watch(selected, (value) => {
    emit('select', value);
  });

  const open = ref(false);

  function onOpen() {
    open.value = true;
  }

  function onClose() {
    open.value = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="select" :class="{ small }" @wheel="(e) => open && e.stopPropagation()">
    <VueSelect v-model="selected" :options="items" transition="" :clearable="false" @open="onOpen" @close="onClose" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .select {
    &:deep(.v-select) {
      --vs-controls-color: #000000;
      --vs-border-color: transparent;

      --vs-dropdown-bg: #ffffff;
      --vs-dropdown-color: #000000;
      --vs-dropdown-option-color: #000000;

      --vs-selected-bg: #ffffff;
      --vs-selected-color: #000000;

      --vs-search-input-color: #000000;

      --vs-dropdown-option--active-bg: #eaeaea;
      --vs-dropdown-option--active-color: #000000;

      --vs-dropdown-max-height: 306px;
      background-color: $white;

      @include roboto-regular(px(16));

      border: none;
      border-radius: px(2);

      .vs__selected {
        margin: 0;
      }

      &.vs--open {
        border-radius: px(2) px(2) 0 0;

        .vs__open-indicator {
          transform: scale(0.85, -0.76);
          margin-top: -0.1rem;
        }
      }

      .vs__dropdown-toggle {
        height: px(80);
        padding: 0 px(25);
      }

      .vs__actions {
        padding: 0;
      }

      .vs__dropdown-menu {
        padding: 0;
        border-radius: 0 0 px(2) px(2);

        border: none;
        border-top: px(1) solid $grey-5;
      }

      .vs__selected-options {
        align-items: center;
        padding: 0;
      }

      .vs__dropdown-option {
        padding: px(14) px(31);
        border-bottom: px(1) solid $grey-5;
      }

      .vs__open-indicator {
        transition: none;
        transform: scale(0.85, 0.76);
      }

      .vs__dropdown-option--selected {
        @include roboto-bold(px(16));
      }

      @include break-small {
        .vs__dropdown-toggle {
          height: px(64);
          padding: 0 px(18);
        }

        .vs__dropdown-option {
          padding-left: px(24);
        }
      }

      @include break-phone {
        .vs__dropdown-toggle {
          height: px(44);
          padding: 0 px(14) 0 px(12);
        }

        .vs__dropdown-option {
          padding-left: px(18);
        }
      }
    }

    &.small {
      &:deep(.v-select) {
        .vs__dropdown-toggle {
          height: px(48);
          padding: 0 px(14) 0 px(12) !important;
        }

        .vs__dropdown-option {
          padding-left: px(18) !important;
        }
      }
    }
  }
</style>
