<template>
  <svg width="33" height="14" viewBox="0 0 33 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.4086 6.99989L24.8086 2.39989L26.3999 0.808594L32.5912 6.99989L26.3999 13.1912L24.8086 11.5999L29.4086 6.99989Z"
      fill="white"
      style="fill: white; fill: white; fill-opacity: 1"
    />
    <path
      d="M6.99989 13.1912L0.808594 6.99989L6.99989 0.808594L8.59119 2.39989L3.99119 6.99989L8.59119 11.5999L6.99989 13.1912Z"
      fill="white"
      style="fill: white; fill: white; fill-opacity: 1"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(33);
  }
</style>
