<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation
    ><Card><slot /></Card
  ></ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    position: relative;

    background-color: $black;
    padding: px(36) px(36) 0;

    @include break-small {
      padding: px(52) 0 0;
    }
  }
</style>
