export default (ref, onSwipeLeft, onSwipeRight) => {
  let touchStart = 0.0;
  let touchEnd = 0.0;

  onMounted(() => {
    ref.value.addEventListener('touchstart', onTouchStart);
    ref.value.addEventListener('touchend', onTouchEnd);
  });

  onBeforeUnmount(() => {
    ref.value && ref.value.removeEventListener('touchstart', onTouchStart);
    ref.value && ref.value.removeEventListener('touchend', onTouchEnd);
  });

  function onTouchStart(e) {
    touchStart = e.changedTouches[0].screenX;
  }

  function onTouchEnd(e) {
    touchEnd = e.changedTouches[0].screenX;

    if (touchEnd < touchStart) onSwipeLeft();
    else if (touchEnd > touchStart) onSwipeRight();
  }
};
