<script setup>
  const { item } = defineProps(['item']);

  const url = ref(item.url);
  const type = item.blueprint.handle;

  if (type === 'youtube') {
    url.value = item.youtube_link;
  }

  if (item.video && item.video.length) {
    item.video = item.video[0];
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ClickableCard :to="url" :class="type">
    <div>
      <Picture preset="card-small" :image="useThumbnail(item)" />
      <div class="youtube-logo"><img :src="useCdn().generateStaticAsset('images/youtube.png')" alt="youtube" /></div>
      <PlayButton class="play" />
    </div>
    <NewsInfo :item="item" />
  </ClickableCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    background-color: $white;

    width: px(628);

    display: flex;

    gap: px(4);
    height: 100%;

    picture {
      width: px(302);
      height: 100%;

      min-height: px(260);
      flex-shrink: 0;
    }

    aside {
      padding: 20px 20px 22px;
      height: 100%;
    }

    > div {
      position: relative;
    }

    .play {
      display: none;

      position: absolute;

      left: 50%;
      top: 50%;

      width: px(60);
      height: px(60);

      transform: translate(-50%, -50%);
    }

    .youtube-logo {
      position: absolute;
      display: none;

      bottom: 0;
      right: 0;

      width: 156px;
      margin-bottom: -10px;
      margin-right: -5px;
    }

    &.video {
      .play {
        display: flex;
      }
    }

    &.youtube {
      .youtube-logo {
        display: block;
      }
    }

    @include hover {
      :deep(a) {
        background-color: $red;
        path {
          fill: $white;
        }
      }
    }

    @include break-small {
      flex-direction: column;
      width: 100%;

      picture {
        width: 100%;
        height: px(221);
        min-height: 0;
      }

      aside {
        padding: 16px 28px 60px;
      }
    }
  }
</style>
