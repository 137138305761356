<script setup>
  const { data } = defineProps(['data']);
  const { image, caption } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap full>
    <ShowAnimation fadeOnly
      ><figure>
        <Picture preset="landscape-1280" :image="image" /></figure
    ></ShowAnimation>
    <ShowAnimation
      ><Caption v-if="caption">{{ caption }}</Caption></ShowAnimation
    >
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: $block-padding-large 0 $block-padding-medium;

    @include break-medium {
      padding: $block-padding-large 0;
    }

    @include break-small {
      padding: px(40) 0;
    }

    @include break-phone {
      figcaption {
        margin-left: $margin-phone;
        margin-right: $margin-phone;
      }
    }

    :deep(picture) {
      @include break-phone {
        // height: 70vw;
        // max-height: px(380);
      }
    }
  }
</style>
