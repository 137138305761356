<script setup>
  const { event } = defineProps({ event: Object, high: Boolean });

  const emit = defineEmits(['fightSelect']);

  // --

  const whiteCorner = ref({ last_name: '', thumbnail: null, left_image: null });
  const blackCorner = ref({ last_name: '', thumbnail: null, right_image: null });
  const fightTitle = ref('');

  function onFightSelect(fight) {
    if (fight) {
      whiteCorner.value = fight.white_corner;
      blackCorner.value = fight.black_corner;
      fightTitle.value = fight.weight_class?.title + ' ' + fight.fight_type?.title;
    }

    emit('fightSelect', fight);
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation>
    <EventCard class="event-card" :class="{ high }" :event="event" @fightSelect="onFightSelect">
      <template v-slot:left>
        <NuxtLink :to="event.url" class="heading">
          <EventLogo :event="event" />
          <EventDateLocation small :event="event" />
        </NuxtLink>
        <slot />
        <ButtonGroup
          v-if="event.buttons.length"
          class="buttons"
          :buttons="event.buttons"
          :event="event"
          allowThree
          center
          stackMobile
        />
      </template>
      <template v-slot:right>
        <UpcomingEventPredraw v-if="event.predraw" :event="event" />
        <div v-if="!event.predraw" class="top">
          <EventCardFightTitle>{{ fightTitle }}</EventCardFightTitle>
        </div>
        <div v-if="!event.predraw" class="fighters">
          <UpcomingEventFighter :fighter="whiteCorner" corner="left_image"/>
          <UpcomingEventFighter :fighter="blackCorner" corner="right_image"/>
        </div>
      </template>
      <div class="buttons-bottom">
        <ButtonGroup
          v-if="event.buttons.length"
          class="buttons"
          :buttons="event.buttons"
          :event="event"
          allowThree
          center
          stackMobile
        />
      </div>
      <VideoBackground v-if="event.header_video" :src="event.header_video.url" class="background" />
    </EventCard>
  </ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .event-card {
    overflow: hidden;
    background: $black;

    .background {
      @include break-small {
        height: 50%;
      }
    }

    &.predraw {
      .background {
        @include break-small {
          height: 60%;
        }
      }
    }

    @include break-min-small {
      height: px(500);
      max-height: px(500);
    }

    &.high {
      @include break-min-small {
        :deep(.left-side) {
          padding: px(48) px(32);
        }
      }
    }

    :deep(.left-side) {
      padding: px(90) px(32);

      .heading {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          margin-top: px(4);
        }
      }

      @include break-min-small {
        width: calc(100% - px(404));
      }

      justify-content: space-between;

      @include break-small {
        height: px(286);

        .heading {
          .info {
            margin-top: px(2);
          }
        }
        .buttons {
          display: none;
        }
      }
    }

    &.predraw {
      :deep(.right-side) {
        @include break-small {
          position: static;
          height: 100%;
        }
      }
    }

    &:not(.predraw) {
      :deep(.right-side) {
        @include break-min-small {
          background-color: $black;
          border-left: px(2) solid $black-5;

          &:before {
            content: '';

            position: absolute;

            width: px(120);
            height: 100%;

            left: px(-121); // border fix

            background: linear-gradient(90deg, transparent 0%, $black 100%);
          }
        }

        @include break-small {
          background: linear-gradient(0, $black 80%, transparent 100%);
        }
      }
    }

    :deep(.right-side) {
      @include break-min-small {
        width: px(404);

        flex-shrink: 0;
        flex-grow: 0;
      }

      .top {
        width: 100%;
        height: px(125);
        min-height: px(125);

        padding: 0 px(32);

        display: flex;
        align-items: center;
        justify-content: center;

        border-bottom: px(1) solid $black-5;

        @include break-small {
          height: auto;
          min-height: 0;

          h3 {
            margin: px(64) 0 px(24);
          }
        }
      }

      .fighters {
        position: relative;

        display: flex;

        width: 100%;
        height: 100%;

        > * {
          &:first-child {
            border-right: px(1) solid $black-5;
          }
        }

        &:after {
          content: 'vs';
          @include heroic-bold(px(24));

          position: absolute;
          pointer-events: none;

          left: 50%;
          top: calc(50% - px(36)); // half .name bar

          transform: translate(-50%, -50%);
          background-color: $black;

          padding: px(5) px(4) px(1);

          @include break-small {
            top: calc(50% - px(40)); // half .name bar
            font-size: px(22);
          }

          @include break-phone {
            top: calc(50% - px(30)); // half .name bar
            font-size: px(20);
          }
        }
      }

      .bottom {
        height: px(77);

        border-top: px(1) solid $black-5;
      }
    }

    .buttons {
      margin-top: px(48);

      @include break-min-small {
        width: px(320);
        max-width: 100%;

        @include break-large {
          width: px(276);
        }
      }
    }

    .buttons-bottom {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0 px(16);

      z-index: 4;

      .buttons {
        margin-top: px(32);
        @include break-phone {
          margin-top: px(16);
        }
      }

      @include break-min-small {
        display: none;
      }
    }

    @include break-small {
      height: auto;
      padding: px(96) 0 px(28);

      &.gold {
        .top {
          h3 {
            @include gold-text;
          }
        }
      }

      :deep(.left-side) {
        padding: 0 px(16);
      }

      :deep(.right-side) {
        .bottom {
          padding: 0 px(16);
        }
      }
    }
  }
</style>
