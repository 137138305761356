<script setup>
  import HomeHeroTitleFight from '~/components/parts/home/HomeHeroTitleFight.vue';
  import HomeHeroTournament from '~/components/parts/home/HomeHeroTournament.vue';
  import HomeHeroTournamentTitleFight from '~/components/parts/home/HomeHeroTournamentTitleFight.vue';

  // --

  const { events } = defineProps(['events']);

  const heroes = {
    title_fight: HomeHeroTitleFight,
    tournament: HomeHeroTournament,
    tournament_title: HomeHeroTournamentTitleFight,
  };

  const event = ref(events[0]);
  const eventType = computed(() => event.value.event_type.value);

  let currentIndex = 0;

  function onEventSelect(index) {
    currentIndex = index;
    event.value = events[currentIndex];
  }

  function onPrevClick() {
    currentIndex = currentIndex > 0 ? currentIndex - 1 : events.length - 1;
    event.value = events[currentIndex];
    bar.value.select(currentIndex);
  }

  function onNextClick() {
    currentIndex = currentIndex < events.length - 1 ? currentIndex + 1 : 0;
    event.value = events[currentIndex];
    bar.value.select(currentIndex);
  }

  // --

  const bar = ref();
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide>
    <HomeHeroProgressBar ref="bar" v-if="events.length > 1" :events="events" @eventSelect="onEventSelect" />
    <component :is="heroes[eventType]" :event="event" :key="currentIndex"/>
    <button v-if="events.length > 1" class="nav-button prev" @click="onPrevClick" aria-label="previous" />
    <button v-if="events.length > 1" class="nav-button next" @click="onNextClick" aria-label="next" />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-top: $header-height !important;
    padding-bottom: px(56);

    height: px(732);
    max-height: 82vw;

    .nav-button {
      position: absolute;

      background-color: $red;

      width: 50%;
      height: 100%;

      top: 0;
      opacity: 0;

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }

    @include break-min-small {
      .nav-button {
        display: none;
      }
    }

    :deep(> div) {
      height: 100%;
    }

    :deep(.grid) {
      height: 100%;
    }

    @include break-small {
      padding-top: calc($mobile-header-height + px(9));
    }

    @include break-small {
      height: px(780);
      max-height: none;

      :deep(.grid) {
        display: flex;
        flex-direction: column;
      }
    }

    @include break-phone {
      padding-bottom: px(32);
    }
  }
</style>
