<script setup>
  const { item } = defineProps(['item']);
  const { url } = item;
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation>
    <ClickableCard :to="url">
      <div>
        <!-- <Picture preset="card-small" :image="item.thumbnail" /> -->
        <FighterThumbnail :fighter="useOverviewThumbnail(item)" :inactive="item.retired" />
        <span class="name">{{ item.title }}</span>
      </div>
    </ClickableCard>
  </ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .name {
    display: block;

    color: white;
    margin-top: px(16);

    padding-right: px(16);

    @include break-small {
      font-size: px(14);
      margin-top: px(10);
    }

    @include break-phone {
      font-size: px(11);
      margin-top: px(8);
    }
  }
</style>
