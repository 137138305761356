import { defineStore } from 'pinia';
import { statamicRequest } from '~/pinkyellow/satanic-api';

export const useFightHistoryStore = defineStore('fight-history', {
  state: () => ({
    fights: [],
  }),

  getters: {
    getUpcomingFight(state) {
      // let upcomingFights = [];

      for (const fight of state.fights) {
        if (fight.event.starts_at.slice(0, 10) >= new Date().toISOString().slice(0, 10)) {
          return fight;
          // upcomingFights.push(fight);
        }
      }

      return null;
      // return upcomingFights;
    },

    getPastFights(state) {
      let pastFights = [];

      for (const fight of state.fights) {
        if (fight.event.starts_at.slice(0, 10) < new Date().toISOString().slice(0, 10)) {
          pastFights.push(fight);
        }
      }

      return pastFights;
    },
  },

  actions: {
    async fetch(fighter) {
      const fights = await statamicRequest({ uri: 'fighter/fights/' + fighter });

      if (!fights) {
        this.fights = [];
        return;
      }

      fights.fights.sort((a, b) => {
        return new Date(b.event.starts_at) - new Date(a.event.starts_at);
      });

      this.fights = fights.fights;
    },
  },
});
