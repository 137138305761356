<script setup>
  const { total } = defineProps(['total']);

  const emit = defineEmits(['fightSelect']);
  const index = ref(0);

  function prev() {
    index.value = index.value > 0 ? index.value - 1 : total - 1;
  }

  function next() {
    index.value = index.value < total - 1 ? index.value + 1 : 0;
  }

  function select(i) {
    index.value = i;
  }

  watch(index, () => {
    emit('fightSelect', index.value);
  });

  defineExpose({ prev, next });
</script>

<!----------------------------------------------------------------------------->

<template>
  <ul>
    <li class="prev"><ArrowButton small left white @click="prev" /></li>
    <li v-for="i in total" class="page-item" :class="{ active: i - 1 === index }" @click="() => select(i - 1)"></li>
    <li class="next"><ArrowButton small white @click="next" /></li>
  </ul>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  ul {
    display: inline-flex;
    gap: px(8);

    min-width: px(236);

    align-items: center;
    justify-content: center;

    .page-item {
      background-color: $white-20;
      width: px(6);
      height: px(6);

      border-radius: 100%;

      pointer-events: auto;

      @include hitbox(px(4));
      cursor: pointer;

      @include fade('background-color', 0.29s);

      @include hover {
        background: $red;
        @include fade('background-color', 0.04s);
      }

      &.active {
        background-color: $white;
      }
    }

    .prev {
      margin-right: auto;
    }

    .next {
      margin-left: auto;
    }

    @include break-small {
      .prev {
        display: none;
      }

      .next {
        display: none;
      }
    }

    @include break-phone {
      // width: 100%;
      // min-width: 0;
    }
  }
</style>
