<script setup>
  const { item } = defineProps(['item']);

  const total = item.data.valueA + item.data.valueB;

  const ratio = item.data.valueA / total;
</script>

<!----------------------------------------------------------------------------->

<template>
  <FighterStatCard :title="item.title" :subtitle="item.subtitle">
    <h5>{{ item.data.valueA }}:{{ item.data.valueB }}</h5>
    <div class="bar-container"><div class="bar" :style="{ '--ratio': ratio }" /></div>
  </FighterStatCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  :deep(.stat) {
    position: relative;
    max-width: px(200);

    h5 {
      margin-top: px(-72);
    }

    .bar-container {
      position: absolute;
      width: 100%;

      margin-top: px(110);
    }

    .bar {
      position: relative;

      width: 100%;
      height: px(12);

      background-color: $black-5;

      &:after {
        content: '';
        position: absolute;

        background-color: $red;

        width: 100%;
        height: 100%;

        left: 0;

        transform-origin: left top;
        transform: scaleX(var(--ratio));
      }
    }
  }
</style>
