<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2474_22444)">
      <path d="M16.4197 16.0469H2.73828V18.7832H16.4197V16.0469Z" fill="url(#paint0_linear_2474_22444)" style="" />
      <path
        d="M17.7858 2.36619C17.0333 2.36619 16.4177 2.98186 16.4177 3.73433C16.4177 4.22686 16.6913 4.65098 17.088 4.88357L14.9537 8.42705C14.3928 9.35738 13.0384 9.30265 12.5595 8.33128L10.1789 3.55647C10.6304 3.33757 10.9451 2.88609 10.9451 2.35251C10.9451 1.60004 10.3294 0.984375 9.57697 0.984375C8.82449 0.984375 8.20883 1.60004 8.20883 2.35251C8.20883 2.88609 8.5235 3.33757 8.97499 3.55647L6.59443 8.33128C6.11558 9.30265 4.74744 9.3437 4.20018 8.42705L2.06589 4.86989C2.46265 4.6373 2.73628 4.21318 2.73628 3.72065C2.73628 2.96818 2.12061 2.35251 1.36814 2.35251C0.615662 2.35251 0 2.96818 0 3.72065C0 4.47313 0.615662 5.08879 1.36814 5.08879C1.42286 5.08879 1.47759 5.06143 1.53231 5.04774L2.73628 14.6658H16.4177L17.6216 5.04774C17.6216 5.04774 17.7311 5.08879 17.7858 5.08879C18.5383 5.08879 19.1539 4.47313 19.1539 3.72065C19.1539 2.96818 18.5383 2.35251 17.7858 2.35251V2.36619Z"
        fill="url(#paint1_linear_2474_22444)"
        style=""
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2474_22444"
        x1="2.73828"
        y1="16.0469"
        x2="16.4197"
        y2="16.0469"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#EEC99E"
          style="stop-color: #eec99e; stop-color: color(display-p3 0.9333 0.7884 0.6183); stop-opacity: 1"
        />
        <stop
          offset="1"
          stop-color="#FFEEC2"
          style="stop-color: #ffeec2; stop-color: color(display-p3 1 0.9335 0.7625); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2474_22444"
        x1="0"
        y1="0.984375"
        x2="19.1539"
        y2="0.984375"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#EEC99E"
          style="stop-color: #eec99e; stop-color: color(display-p3 0.9333 0.7884 0.6183); stop-opacity: 1"
        />
        <stop
          offset="1"
          stop-color="#FFEEC2"
          style="stop-color: #ffeec2; stop-color: color(display-p3 1 0.9335 0.7625); stop-opacity: 1"
        />
      </linearGradient>
      <clipPath id="clip0_2474_22444">
        <rect width="20" height="19" fill="white" style="fill: white; fill: white; fill-opacity: 1" />
      </clipPath>
    </defs>
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(20);
  }
</style>
