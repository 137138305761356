<script setup>
  const { event } = defineProps({ event: Object });

  // --

  const colorName = useEventColor(event);

  const emit = defineEmits(['fightSelect']);

  const card = ref();
  const nav = ref();

  onSwipe(
    card,
    // swipe left
    () => {
      nav.value.next();
    },

    // swipe right
    () => {
      nav.value.prev();
    },
  );

  // --

  let x = 0.0;
  let y = 0.0;

  onMounted(() => {
    card.value.addEventListener('touchmove', onTouchMove);
  });

  onBeforeUnmount(() => {
    card.value && card.value.removeEventListener('touchmove', onTouchMove);
  });

  function onTouchMove(e) {
    const newX = e.changedTouches[0].screenX;
    const newY = e.changedTouches[0].screenY;

    if (Math.abs(newX - x) > Math.abs(newY - y)) e.preventDefault();

    x = newX;
    y = newY;
  }

  // --

  function onFightSelect(index) {
    emit('fightSelect', event.fight_cards[index]);
  }

  onMounted(() => {
    emit('fightSelect', event.fight_cards[0]);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Card :class="event.predraw ? 'predraw' : ''" :color="colorName">
    <div class="left-side">
      <slot name="left" />
    </div>
    <div ref="card" class="right-side">
      <div>
        <slot name="right" />
      </div>
      <div v-if="!event.predraw" class="bottom">
        <SliderNav ref="nav" :total="event.fight_cards.length" @fightSelect="onFightSelect" class="nav" />
      </div>
    </div>
    <slot />
    <NuxtLink :to="event.url" :target="useLinkTarget(event.url)" class="link" aria-label="navigate to event" />
  </Card>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    display: flex;
    background-color: $black-2;

    // cursor: default;

    .link {
      position: absolute;

      width: 100%;
      height: 100%;
    }

    .left-side {
      position: relative;
      z-index: 2;

      display: flex;
      flex-direction: column;
      align-items: center;

      pointer-events: none;

      :deep(a),
      :deep(button) {
        pointer-events: auto;
      }
    }

    .right-side {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      pointer-events: none;

      :deep(a),
      :deep(button) {
        pointer-events: auto;
      }

      > div {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        @include break-min-small {
          overflow: hidden;
        }
      }

      .bottom {
        flex-shrink: 0;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @include break-small {
      flex-direction: column;

      .left-side {
        width: 100%;
      }

      .right-side {
        width: 100%;
      }
    }
  }
</style>
