<script setup>
  const { event } = defineProps(['event']);

  const hasStarted = useDate().hasStarted(event.starts_at);
</script>

<!----------------------------------------------------------------------------->

<template>
  <HomeHeroCard :event="event">
    <div class="heading">
      <EventLogo small :event="event" class="logo" />
      <EventDateLocation v-if="!hasStarted" :event="event" class="date-location" />
    </div>
    <h2>
      <span>{{ event.size.label }}</span> {{ event.tournament_title }}
    </h2>
    <TickerTape :items="event.tournament_fighter_names" class="ticker" />
    <ul>
      <li v-for="fighter in event.tournament_fighter_names">{{ fighter }}</li>
    </ul>
  </HomeHeroCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .heading {
    margin: px(92) 0 px(34);

    display: flex;
    align-items: flex-end;

    gap: px(24);

    .logo {
      margin-bottom: px(-7);
    }

    .date-location {
      margin-bottom: -0.45em;
    }
  }

  h2 {
    @include title-xl;
  }

  .ticker {
    margin: px(12) 0 px(40);
  }

  ul {
    flex-direction: column;
    align-items: center;
    gap: px(5);

    @include title-xs;
    text-align: center;

    margin-top: px(22);

    display: none;
  }

  @include break-small {
    .heading {
      flex-direction: column;
      align-items: center;

      gap: px(4);
      margin-top: 0;
      margin-bottom: px(20);

      .logo {
        margin-bottom: 0;
      }

      .date-location {
        margin-bottom: 0;
      }
    }

    h2 {
      text-align: center;
      @include title-xs;

      span {
        @include title-l;
        display: block;

        margin-bottom: px(4);
      }
    }

    .ticker {
      display: none;
    }

    ul {
      display: flex;
    }
  }

  @include break-phone {
    .heading {
      // margin-top: 22vw;
    }

    ul {
      li {
        line-height: 0.8;
      }
    }
  }
</style>
