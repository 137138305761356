<script setup>
  import FighterStat from '~/components/parts/stats/FighterStat.vue';

  // --

  const { stats } = defineProps(['stats']);


  const items = [
    {
      type: 'record',
      title: 'Glory Record',
      subtitle: '',
      data: [
        { label: 'Wins', value: stats.GloryRecord.wins },
        { label: 'Losses', value: stats.GloryRecord.losses },
        { label: 'Draws', value: stats.GloryRecord.draws },
        { label: 'KO', value: stats.GloryRecord.kos },
      ],
    },
    {
      type: 'time',
      title: 'Average fight time',
      subtitle: 'Fight Duration (max. 15min)',
      data: { time: stats.AverageFightTime, total: '00:15:00' },
    },
    {
      type: 'ratio',
      title: 'Knockdown Ratio',
      subtitle: 'Knockdowns *Landed# / Absorbed',
      data: { valueA: stats.KnockdownsLanded, valueB: stats.KnockdownsAbsorbed },
    },
    {
      type: 'number',
      title: 'SLpM',
      subtitle: 'Strikes Landed per Minute',
      data: { value: stats.SLpM },
    },
    {
      type: 'difference',
      title: 'Striking Differential',
      subtitle: 'Difference *SLpM# and SApM',
      data: { valueA: stats.SLpM, valueB: stats.SApM },
    },
    {
      type: 'number',
      title: 'SApM',
      subtitle: 'Strikes Absorbed per Minute',
      data: { value: stats.SApM },
    },
    {
      type: 'percentage',
      title: 'Striking Accuracy',
      subtitle: 'Proportion of Strikes Landed',
      data: { value: stats.StrikingAccuracy, total: 100.0 },
    },
  ];
</script>

<!----------------------------------------------------------------------------->

<template>
  <SliderSection :items="items" :slide="FighterStat" narrowCards>
    <slot />
  </SliderSection>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
