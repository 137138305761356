<script setup>
  const { item } = defineProps(['item']);

  const url = ref(item.url);
  const type = item.blueprint.handle;

  if (type === 'youtube') {
    url.value = item.youtube_link;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation fadeOnly class="container">
    <ClickableCard :to="url" :class="type">
      <div>
        <div class="picture-container"><Picture preset="card-small" :image="useThumbnail(item)" /></div>
        <div class="youtube-logo"><img :src="useCdn().generateStaticAsset('images/youtube.png')" alt="youtube" /></div>
      </div>
      <NewsInfo :item="item" />
    </ClickableCard>
  </ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    width: 100%;
    max-width: 50%;

    @include break-large {
      max-width: 100%;
    }
  }
  .card {
    display: flex;
    gap: calc(2 * $grid-gap);

    .picture-container,
    aside {
      width: 100%;
    }

    > div {
      position: relative;
      align-self: flex-start;
    }

    .picture-container {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      picture {
        transform: scale(100%);
        transition: transform 0.31s $quadInOut;
      }
    }

    .youtube-logo {
      position: absolute;
      display: none;

      bottom: 0;
      right: 0;

      width: 156px;
      margin-bottom: -10px;
      margin-right: -5px;
    }

    &.youtube {
      .youtube-logo {
        display: block;
      }
    }

    @include hover {
      .picture-container {
        picture {
          transform: scale(105%);
          transition: transform 0.48s $quadOut;
        }
      }

      :deep(.arrow-button) {
        background-color: $red;
        path {
          fill: $white;
        }
      }
    }

    @include break-large {
      gap: $grid-gap;

      @include break-min-small {
        .picture-container {
          max-width: px(400);
        }

        aside {
          max-width: px(520);
        }
      }
    }

    @include break-small {
      flex-direction: column;

      gap: px(20);
    }
  }
</style>
