<script setup>
  import ArticleTemplate from '~/components/templates/ArticleTemplate.vue';
  import EventTemplate from '~/components/templates/EventTemplate.vue';
  import EventOverviewTemplate from '~/components/templates/EventOverviewTemplate.vue';
  import FighterTemplate from '~/components/templates/FighterTemplate.vue';
  import FighterOverviewTemplate from '~/components/templates/FighterOverviewTemplate.vue';
  import GirlsTemplate from '~/components/templates/GirlsTemplate.vue';
  import HomeTemplate from '~/components/templates/HomeTemplate';
  import LandingTemplate from '~/components/templates/LandingTemplate.vue';
  import NewsOverviewTemplate from '~/components/templates/NewsOverviewTemplate.vue';
  import PageTemplate from '~/components/templates/PageTemplate.vue';
  import TicketsOverviewTemplate from '~/components/templates/TicketsOverviewTemplate.vue';
  import VideoTemplate from '~/components/templates/VideoTemplate.vue';
  import WatchOverviewTemplate from '~/components/templates/WatchOverviewTemplate.vue';
  import GeneratorError from '~/components/generators/GeneratorError.vue';

  // --

  const { page } = defineProps(['page']);

  const store = usePageStore();
  const handle = ref();

  handle.value = store.getBlueprintHandle;

  const templates = {
    article: ArticleTemplate,
    event: EventTemplate,
    event_overview: EventOverviewTemplate,
    fighter: FighterTemplate,
    fighter_overview: FighterOverviewTemplate,
    girls: GirlsTemplate,
    home: HomeTemplate,
    landing: LandingTemplate,
    news_overview: NewsOverviewTemplate,
    page: PageTemplate,
    tickets_overview: TicketsOverviewTemplate,
    video: VideoTemplate,
    watch_overview: WatchOverviewTemplate,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <component :is="templates[handle]" :page="page"></component>
  <GeneratorError v-if="!templates[handle]" :error="`template ${handle} not found`" />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
