<script setup>
  const { categories, skip } = defineProps(['categories', 'skip']);

  const api = useApi();
  const route = useRoute();
  const router = useRouter();

  // --

  const items = ref({});
  const totalItems = ref(0);
  const itemsPerPage = 8;

  const currentPage = ref(route.query.page ? parseInt(route.query.page) : 1);

  // dropdowns

  categories.unshift({ slug: 'all', title: 'All news' });
  const types = [
    { slug: 'all', title: 'All types' },
    { slug: 'article', title: 'Article' },
    { slug: 'video', title: 'Video' },
    { slug: 'youtube', title: 'YouTube' },
  ];

  const category = ref(categories[0].slug);
  const type = ref(types[0].slug);

  async function onCategorySelect(slug) {
    currentPage.value = 1;
    category.value = slug;
    await paginate(currentPage.value);
  }

  async function onTypeSelect(slug) {
    currentPage.value = 1;
    type.value = slug;
    await paginate(currentPage.value);
  }

  const leftActive = ref();
  const rightActive = ref();

  // pagination

  const paginate = async (current) => {
    const options = {
      collection: 'news',
      limit: itemsPerPage,
      page: current,
      sorting: [{ field: 'date' }],
      filters: [
        {
          field: 'id',
          condition: 'not_in',
          value: skip,
        },
        {
          field: 'blueprint',
          value: type.value === 'all' ? null : type.value,
        },
        {
          field: 'category',
          value: category.value === 'all' ? null : category.value,
        },
      ],
    };

    const news = await api.collectionList(options);

    if (!news) {
      items.value = [];
      totalItems.value = 0;
      return;
    }

    items.value = news.data;
    totalItems.value = news.meta.total;

    router.replace({ query: { page: current } });
  };

  provide('totalItems', totalItems);
  provide('currentPage', currentPage);
  provide('paginate', paginate);

  // init

  onBeforeMount(async () => {
    await paginate(currentPage.value);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide v-if="totalItems">
    <ShowAnimation :style="{ 'z-index': 1 }">
      <div class="filters" :class="{ leftActive, rightActive }">
        <Dropdown
          :items="categories"
          @select="onCategorySelect"
          wideMobile
          @open="leftActive = true"
          @close="leftActive = false"
        />
        <Dropdown
          :items="types"
          @select="onTypeSelect"
          right
          wideMobile
          @open="rightActive = true"
          @close="rightActive = false"
        />
      </div>
    </ShowAnimation>

    <ul>
      <li v-for="item in items" :key="item.id">
        <ShowAnimation class="animation-container" immediate>
          <NewsCard :item="item" />
        </ShowAnimation>
      </li>
    </ul>

    <Pagination
      v-if="items.length"
      url="/news?page=[page]"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      type="news item"
    />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    background-color: $grey-6;
    padding-top: px(64);
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: px(10);

    @include break-small {
      padding: 0 px(16);
    }

    @include break-phone {
      &.leftActive {
        > * {
          &:last-child {
            display: none;
          }
        }
      }

      &.rightActive {
        > * {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    align-items: stretch;

    // min-height: 40vh;

    $gap: px(24);

    column-gap: $gap;
    row-gap: px(64);

    li {
      flex: 1 0 21%;
      max-width: calc(25% - 0.25 * 3 * $gap);

      .animation-container {
        height: 100%;
      }
    }

    @include break-large {
      li {
        flex-basis: 40%;
        max-width: calc(50% - 0.5 * $gap);
      }
    }

    @include break-small {
      gap: $margin-small;

      li {
        flex-basis: 100%;
        max-width: none;
      }
    }

    @include break-phone {
      gap: $margin-phone-wide;
    }
  }
</style>
