<script setup>
  import CtaButtonBlock from '~/components/blocks/CtaButtonBlock.vue';
  import FaqBlock from '~/components/blocks/FaqBlock.vue';
  import ImageBlock from '~/components/blocks/ImageBlock.vue';
  import NewsletterPromoBlock from '~/components/blocks/NewsletterPromoBlock.vue';
  import QuoteBlock from '~/components/blocks/QuoteBlock.vue';
  import TextCtaBlock from '~/components/blocks/TextCtaBlock.vue';
  import TwoColumnsBlock from '~/components/blocks/TwoColumnsBlock.vue';
  import VideoEmbedBlock from '~/components/blocks/VideoEmbedBlock.vue';
  import GeneratorError from '~/components/generators/GeneratorError.vue';

  // --

  const props = defineProps(['content']);
  const content = ref(props.content);
  // const { content } = props;

  const blocks = {
    cta_button: CtaButtonBlock,
    faq: FaqBlock,
    image: ImageBlock,
    newsletter_promo: NewsletterPromoBlock,
    quote: QuoteBlock,
    text_cta: TextCtaBlock,
    two_columns: TwoColumnsBlock,
    video_embed: VideoEmbedBlock,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <component
    v-for="block in content"
    :key="block.id"
    :is="blocks[block.type] || GeneratorError"
    :data="block"
    :error="`block ${block.type} not found`"
  />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
