<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.42086 11.5791H0V8.42086H8.42086V0H11.5791V8.42086H20V11.5791H11.5791V20H8.42086V11.5791Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(20);
  }
</style>
