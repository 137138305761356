<script setup>
  const { data } = defineProps(['data']);
  const { image } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation fadeOnly>
    <figure>
      <Picture preset="portrait-small" :image="image" /></figure
  ></ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .column {
    &.left {
      @include column(1, 5);
    }

    &.right {
      @include column(8, 12);
    }

    @include break-small {
      margin-bottom: $block-padding-extra-large;
      grid-row: 1;

      &.left {
        @include column(1, 6);
        @include unwrap-left;
      }

      &.right {
        @include column(7, 12);
        @include unwrap-right;
      }
    }

    @include break-phone {
      margin-bottom: $block-padding-medium;
      &.left {
        @include column(1, 8);
        @include unwrap-left;
      }

      &.right {
        @include column(5, 12);
        @include unwrap-right;
      }
    }
  }
</style>
