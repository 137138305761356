<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap>
    <GridItem :from="2" :to="8" class="wrapper">
      <BackButton to="/news" white><slot /></BackButton
    ></GridItem>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    margin-bottom: -$content-padding-bottom;
    padding: px(56) 0px px(118);

    background-color: $black-3;

    @include break-small {
      padding: 66px 0px 74px;
    }
  }
</style>
