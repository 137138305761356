<script setup>
  const { event } = defineProps(['event']);

  const longAgo = useDate().longAgo(event.ends_at);
  const formattedDate = useDate().format(event.starts_at);
  const time = useDate().formatTime(event.starts_at);
  const prelims = useDate().formatTime(event.prelims_starts_at);
  const timezone = useDate().guessTimezone()
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="info" :class="{ longAgo }">
    <div v-if="longAgo && event.venue">
      <span class="location-large">{{ event.venue }} {{ event.city }}</span>
    </div>
    <div v-else>
      <div>
        <span>
          {{ formattedDate }}<span class="time-top">&nbsp;<Bullet />&nbsp;{{ time }} {{ timezone }}</span>
        </span>
      </div>
      <div>
        <span class="time-bottom">{{ time }} {{ timezone }}&nbsp;<Bullet /></span>
        <span v-if="event.prelims_starts_at">Prelims {{ prelims }} {{ timezone }}</span>
        <span class="location-top" v-if="event.venue"><Bullet />&nbsp;{{ event.venue }} {{ event.city }}</span>
      </div>
      <div v-if="event.venue" class="location-bottom">
        <span>{{ event.venue }} {{ event.city }}</span>
      </div>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    padding: 0 px(36);

    @include industry-demi(1vw);

    &.longAgo > div {
      margin-top: 0;

      span {
        margin-bottom: -0.08em;

        @include break-large {
          font-size: 1.5vw;
        }

        @include break-medium {
          font-size: 1.8vw;
        }

        @include break-small {
          font-size: px(12);
        }
      }
    }

    > div {
      margin-top: -0.25em;

      div {
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        margin-bottom: px(-5);
      }
    }

    span {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      white-space: nowrap;

      i {
        margin: -0.1em 0.4em 0;
      }
    }

    .location-large {
      font-size: px(20);
    }

    .time-bottom,
    .location-bottom {
      display: none;
    }

    @include break-large {
      padding: 0 px(28);

      font-size: px(12);

      div {
        display: flex;
        flex-direction: column;

        gap: px(4);

        span {
          i {
            @include break-min-small {
              margin-right: 0.25em;
            }
          }
        }
      }

      @include break-min-small {
        .time-top,
        .location-top {
          display: none;
        }

        .time-bottom,
        .location-bottom {
          display: flex;
        }
      }
    }

    @include break-medium {
      padding: 0 px(24);
      font-size: px(11);
    }

    @include break-small {
      align-items: center;
      font-size: px(12);

      div {
        align-items: center;

        div {
          width: auto;
        }
      }
    }
  }
</style>
