<script setup>
  import { watchDebounced } from '@vueuse/core';
  import { useTaxonomyStore } from '~/stores/taxonomy';

  const api = useApi();
  const route = useRoute();
  const router = useRouter();

  // --

  const items = ref({});
  const totalItems = ref(0);
  const itemsPerPage = 24;

  const currentPage = ref(route.query.page ? parseInt(route.query.page) : 1);

  // dropdowns

  const sorting = [
    { slug: 'asc', title: 'All fighters (A-Z)' },
    { slug: 'desc', title: 'All fighters (Z-A)' },
  ];

  const weightClasses = [
    { slug: '', title: 'All divisions' },
    ...useTaxonomyStore().getFilteredTaxonomy('weight_classes'),
  ];

  const sort = ref(sorting[0].slug);
  const weightClass = ref(null);
  const nameQuery = ref(null);

  async function onSortSelect(slug) {
    sort.value = slug;
    await paginate(currentPage.value);
  }

  async function onWeightClassSelect(slug) {
    weightClass.value = slug;
    await paginate(currentPage.value);
  }

  // pagination

  const paginate = async (current) => {
    const options = {
      collection: 'fighters',
      limit: itemsPerPage,
      page: current,

      fields: ['id', 'title', 'url', 'thumbnail', 'thumbnail_overview', 'retired'],

      sorting: [
        {
          field: 'title',
          direction: sort.value,
        },
      ],
      filters: [
        {
          field: 'title',
          condition: 'contains',
          value: nameQuery.value,
        },
        {
          field: 'weight_class',
          condition: (weightClass.value !== 'catchweight') ? 'equals' : 'contains',
          value: weightClass.value,
        },
      ],
    };

    const fighters = await api.collectionList(options);

    if (!fighters) {
      items.value = [];
      totalItems.value = 0;
      return;
    }

    items.value = fighters.data;
    totalItems.value = fighters.meta.total;

    router.replace({ query: { page: current } });
  };

  provide('totalItems', totalItems);
  provide('currentPage', currentPage);
  provide('paginate', paginate);
  provide('searchQuery', nameQuery);

  watchDebounced(
    nameQuery,
    async (query) => {
      if (query === '') {
        nameQuery.value = null;
      }
      await paginate(currentPage.value);
    },
    { debounce: 700 },
  );

  // init

  onBeforeMount(async () => {
    await paginate(currentPage.value);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide v-if="totalItems">
    <ShowAnimation fadeOnly class="animation-container">
      <div class="filters">
        <Dropdown :items="sorting" @select="onSortSelect" black />
        <Dropdown :items="weightClasses" @select="onWeightClassSelect" black />
        <FighterSearch />
      </div>
    </ShowAnimation>

    <ul>
      <li v-for="item in items" :key="item.id">
        <FighterCard :item="item" />
      </li>
    </ul>

    <Pagination
      v-if="items.length"
      url="/fighters?page=[page]"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      type="fighter"
      white
    />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    background-color: $black;
    padding-top: px(64);

    margin-bottom: -$content-padding-bottom;
  }

  .animation-container {
    z-index: 1;
  }

  .filters {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: px(24);

    height: px(42);
    border-bottom: px(1) solid $grey-3;

    margin-bottom: px(64);

    z-index: 10;

    @include break-small {
      margin-bottom: px(48);
      gap: px(12);
    }

    @include break-phone {
      margin-bottom: px(32);
    }

    .dropdown {
      margin-top: px(-18);
      // position: absolute;
      white-space: nowrap;
      min-width: 0;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    align-items: stretch;

    $gap: px(24);
    $gap-large: px(16);
    $small-gap: px(4);

    column-gap: $gap;
    row-gap: px(64);

    li {
      flex: 1 0 15%;
      max-width: calc(16.667% - 0.1667 * 5 * $gap);
    }

    @include break-large {
      column-gap: $gap-large;
      row-gap: px(32);

      li {
        flex-basis: 21%;
        max-width: calc(25% - 0.25 * 3 * $gap-large);
      }
    }

    @include break-small {
      column-gap: $small-gap;

      li {
        flex-basis: 30%;

        max-width: calc(33.33% - 0.3333 * 2 * $small-gap);
      }
    }
  }
</style>
