<script setup>
  const { item } = defineProps(['item']);

  const url = ref(item.url);
  const type = item.blueprint.handle;

  if (type === 'youtube') {
    url.value = item.youtube_link;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ClickableCard :to="url" :class="type">
    <div>
      <div class="picture-container"><Picture preset="card-small" :image="useThumbnail(item)" /></div>
      <div class="youtube-logo"><img :src="useCdn().generateStaticAsset('images/youtube.png')" alt="youtube" /></div>
      <PlayButton class="play" />
    </div>
    <NewsInfo :item="item" />
  </ClickableCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    background-color: $white;

    width: 100%;

    display: flex;
    flex-direction: column;

    gap: px(4);
    height: 100%;

    .picture-container {
      height: px(201);
      flex-shrink: 0;
    }

    aside {
      padding: px(16);
      height: 100%;
    }

    > div {
      position: relative;
    }

    .picture-container {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      picture {
        transform: scale(100%);
        transition: transform 0.31s $quadInOut;
      }
    }

    .play {
      display: none;

      position: absolute;

      left: 50%;
      top: 50%;

      width: px(60);
      height: px(60);

      transform: translate(-50%, -50%);
    }

    .youtube-logo {
      position: absolute;
      display: none;

      bottom: 0;
      right: 0;

      width: 156px;
      margin-bottom: -10px;
      margin-right: -5px;
    }

    &.video {
      .play {
        display: flex;
      }
    }

    &.youtube {
      .youtube-logo {
        display: block;
      }
    }

    @include hover {
      .picture-container {
        picture {
          transform: scale(106%);
          transition: transform 0.48s $quadOut;
        }
      }

      :deep(.arrow-button) {
        background-color: $red;

        path {
          fill: $white;
        }
      }
    }

    @include break-large {
      .picture-container {
        height: px(221);
      }

      aside {
        padding: px(28);
      }
    }

    @include break-small {
      aside {
        padding-top: px(17);
      }
    }
  }
</style>
