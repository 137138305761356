<script setup>
  const props = defineProps({ fighter: Object, inactive: Boolean, noGradientMobile: Boolean });

  // --

  const { fighter } = toRefs(props);
  const imageData = ref();

  watch(
    fighter,
    () => {
      if (fighter.value && typeof fighter.value.thumbnail !== 'string' && fighter.value.thumbnail !== null) {
        imageData.value = useCdn().generatePicture(fighter.value.thumbnail, 'fighter-thumbnail');
      }
    },
    { immediate: true },
  );
  // let imageData;
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="img-wrapper" :class="{ inactive, noGradientMobile }">
    <img v-if="imageData" :src="imageData.imageUrl" :alt="fighter.title" width="308" height="334" />

    <!-- <img
      v-if="fighter && fighter.thumbnail"
      :src="fighter.thumbnail.url"
      :alt="fighter.title"
      width="308"
      height="334"
    /> -->
    <!-- <Picture v-if="fighter && fighter.thumbnail" preset="fighter-thumbnail" :image="fighter.thumbnail" /> -->

    <img
      v-else
      :src="useCdn().generateStaticAsset('images/fighter_silhouette.png')"
      :alt="fighter ? fighter.title : 'TBD'"
      width="308"
      height="334"
    />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .img-wrapper {
    position: relative;

    width: 100%;
    height: 100%;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative;
      z-index: 2;

      display: block;
      // height: auto;
      position: relative;

      aspect-ratio: 308 / 334;
      object-fit: cover;

      transform: scale(100%);
      transition: transform 0.33s $quadInOut;
    }

    @include hover {
      img {
        transform: scale(107%);
        transition: transform 0.48s $quadOut;
      }
    }

    &:before {
      content: '';
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: radial-gradient(circle at center, #474747 0%, #292929 100%);
    }

    &.inactive {
      opacity: 0.7;
      filter: saturate(0);

      &:before {
        opacity: 0.7;
      }
    }

    &.noGradientMobile {
      &:before {
        @include break-small {
          content: none;
        }
      }
    }
  }
</style>
