<script setup>
  import EventHeroTitleFight from '~/components/parts/events/EventHeroTitleFight.vue';
  import EventHeroTournament from '~/components/parts/events/EventHeroTournament.vue';
  import EventHeroTournamentTitleFight from '~/components/parts/events/EventHeroTournamentTitleFight.vue';

  const { event } = defineProps(['event']);
  const buttons = ref({});

  const cards = {
    title_fight: EventHeroTitleFight,
    tournament: EventHeroTournament,
    tournament_title: EventHeroTournamentTitleFight,
  };

  const longAgo = useDate().longAgo(event.ends_at);

  // --

  const video = ref(false);
  const background = ref(false);

  if (event.header_image && event.header_image.extension === 'mp4') {
    video.value = event.header_image.url;
  }

  if (!video.value && event.header_image) {
    background.value = useCdn().generatePicture(event.header_image, 'landscape-1920');
  }

  buttons.value = event.buttons.filter((button) => button.type !== 'fight_button');
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide :class="{ 'sold-out': event.sold_out, 'long-ago': longAgo }">
    <component :is="cards[event.event_type.value]" :event="event" />
    <EventBar class="info-bar" :event="event" />
    <EventBarInfo class="info-bottom" :event="event" />
    <TickerTape
      v-if="event.ticket_sale_ticker && !event.sold_out"
      :items="new Array(event.ticket_sale_ticker)"
      class="tape-tickets"
    />
    <ButtonGroup
      v-if="event.buttons.length"
      class="buttons"
      :buttons="buttons"
      :event="event"
      centerMobile
      stackMobile
    />
    <TickerTape red v-if="event.sold_out" :items="['Sold out']" class="tape-sold-out" />
    <EventBarSponsors :sponsors="event.sponsors" class="sponsors-bottom" />

    <VideoBackground v-if="video" :src="video" class="background video" />
    <div v-else-if="background" class="background" :style="{ 'background-image': `url(${background.imageUrl})` }" />
    <SpotlightBackground v-else />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-top: $header-height !important;
    padding-bottom: px(56);

    .background {
      position: absolute;

      width: 100%;
      height: calc(100% + $header-height);

      top: -$header-height;

      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      @include break-min-small {
        @include dark-layer;

        &:not(.video) {
          @include unwrap;
        }
      }

      &.video {
        &:after {
          background-color: $black-60;
        }
      }

      z-index: $layer-background;

      &:before {
        content: '';

        position: absolute;
        bottom: 0;

        width: 100%;
        height: px(274);

        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
      }

      @include break-small {
        background-size: auto 50%;

        &:before {
          top: 0;
          height: 100%;

          background: linear-gradient(0deg, $black 15%, $black-20 45%, $black-20 55%, $black 85%);
        }
      }
    }

    &.sold-out {
      @include break-min-small {
        padding-bottom: 0;
      }
    }

    .tape-tickets {
      margin-top: px(48);
    }

    .info-bar {
      margin-top: px(24);
    }

    .buttons {
      margin-top: px(48);
    }

    .tape-sold-out {
      margin-top: px(120);
      @include unwrap;
    }

    .info-bottom {
      margin-top: px(16);

      @include break-min-small {
        display: none;
      }
    }

    &.long-ago {
      .info-bottom {
        margin-top: 0;
      }
    }

    .sponsors-bottom {
      opacity: 0.4;
      padding: 0;

      margin-top: px(32);

      @include break-min-small {
        display: none;
      }
    }

    @include break-small {
      padding-bottom: px(24);

      :deep(.grid) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      padding-top: calc($mobile-header-height + px(9));

      .info-bar {
        margin-top: px(16);
      }

      .tape-tickets {
        width: 100vw;
        margin-top: px(24);
      }

      .tape-sold-out {
        width: 100vw;
        margin-left: 0;

        margin-top: px(42);
        margin-bottom: px(32);
      }
    }

    @include break-phone {
      padding-bottom: px(16);
    }
  }
</style>
