<script setup>
  const { small } = defineProps({ small: Boolean, center: Boolean });

  // --

  function onResize(containerWidth, whiteCornerWidth, vsWidth, blackCornerWidth, fontSize) {
    const availableWidth = containerWidth;
    const currentWidth = Math.max(whiteCornerWidth, blackCornerWidth);

    return (availableWidth / currentWidth) * fontSize;
  }
</script>

<template>
  <span :class="{ center }">
    <FightTitle :small="small" :resize="onResize">
      <template v-slot:whiteCorner><slot name="whiteCorner" /></template>
      <template v-slot:blackCorner><slot name="blackCorner" /></template>
    </FightTitle>
  </span>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  span {
    display: flex;
    &.center {
      justify-content: center;
    }

    :deep(.container) {
      flex-direction: column;
      text-align: center;

      font-size: px(172);
      gap: 0.1em;

      .vs {
        font-size: px(32);
        gap: 0.19em;

        margin-top: -0.8em;

        span {
        }

        &:before,
        &:after {
          width: 100%;
          height: px(1);
        }
      }

      &.small {
        font-size: px(102);

        .vs {
          font-size: px(20);
          gap: 0.3em;

          margin-top: -0.6em;
        }
      }

      @include break-phone {
        .vs {
          font-size: 8vw;
        }
      }

      @include break-min-small {
        display: none;
      }
    }
  }
</style>
