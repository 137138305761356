<script setup>
  const { buttons, event } = defineProps({
    buttons: Array,
    event: Object,
    allowThree: Boolean,
    center: Boolean,
    centerMobile: Boolean,
    stackMobile: Boolean,
  });

  function getLabel(button) {
    if(button.type === 'fight_button'){
      const hasEnded = useDate().hasEnded(event.ends_at);
      return hasEnded ? 'Results' : 'Fight Card';
    }
    if(button.type === 'watch_button'){
      return 'Watch';
    }
    if(button.type === 'tickets_button'){
      return 'Tickets';
    }
    return button.label;
  }

  function getUrl(button) {
    if(button.type === 'fight_button'){
      return event.url;
    }
    if(button.type === 'watch_button'){
      return event.url + '#how-to-watch';
    }
    if(button.type === 'tickets_button'){
      return button.ticket_link;
    }
    return button.to;
  }

</script>

<!----------------------------------------------------------------------------->

<template>
  <div
    v-if="buttons.length"
    :class="{
      single: buttons.length === 1,
      double: buttons.length === 2,
      triple: buttons.length === 3 && allowThree,

      center,
      centerMobile,
      stackMobile,
    }"
  >
    <Button red :to="getUrl(buttons[0])" class="first">{{ getLabel(buttons[0]) }}</Button>
    <Button v-if="buttons[1]" white outline :to="getUrl(buttons[1])" class="second">{{ getLabel(buttons[1]) }}</Button>
    <Button v-if="buttons[2] && allowThree" white outline :to="getUrl(buttons[2])" class="third"
      >{{ getLabel(buttons[2]) }}
    </Button>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    width: px(360);

    display: flex;
    flex-wrap: wrap;

    column-gap: px(4);
    row-gap: px(12);

    a {
      width: calc(50% - px(2));
    }

    @include break-phone {
      width: 100%;
    }

    &.single {
      a:first-child {
        width: auto;

        @include break-phone {
          width: 100%;
        }
      }

      &.center {
        justify-content: center;

        a {
          width: 100%;
        }
      }

      @include break-small {
        &.centerMobile {
          justify-content: center;
        }
      }
    }

    &.double {
      @include break-phone {
        &.stackMobile {
          flex-direction: column;

          a {
            width: 100%;
          }
        }
      }
    }

    &.triple {
      a:first-child {
        width: 100%;
      }
    }
  }
</style>
