<script setup>
  import moment from 'moment';

  // --
  const START_OF_DAY = moment().startOf('day');

  const { item } = defineProps(['item']);

  const time = moment(item.data.time, 'HH:mm:ss');
  const total = moment(item.data.total, 'HH:mm:ss');

  const timeValue = time.diff(START_OF_DAY);
  const totalValue = total.diff(START_OF_DAY);

  const percentage = Math.min(timeValue / totalValue, 1.0) * 100.0;
</script>

<!----------------------------------------------------------------------------->

<template>
  <FighterStatCard :title="item.title" :subtitle="item.subtitle">
    <FighterStatCircle :percentage="percentage">
      <h5>{{ time.format('mm:ss') }}</h5>
    </FighterStatCircle>
  </FighterStatCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .stat {
    h5 {
      font-size: px(48);
    }
  }
</style>
