<script setup>
  const { items, right, black, wideMobile } = defineProps({
    items: Array,
    right: Boolean,
    black: Boolean,
    wideMobile: Boolean,
  });

  // --

  const active = ref(false);
  const activeItem = ref(0);

  function onClick() {
    if (!active.value) {
      setTimeout(() => (active.value = true), 1.0);
      emit('open');
    } else {
      active.value = false;
      emit('close');
    }
  }

  // --

  const emit = defineEmits(['open', 'close', 'select']);

  function onItemSelect(index) {
    activeItem.value = index;
    emit('select', items[index].slug);
  }

  // --

  onOutsideClick(() => {
    active.value && (active.value = false);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="dropdown" :class="{ right, black, active, wideMobile }" @click="onClick">
    <label class="main">{{ items[activeItem].title }}<IconArrowVertical /></label>
    <div v-show="active" class="items">
      <label>{{ items[activeItem].title }}<IconArrowVertical /></label>
      <ul>
        <li v-for="(item, index) in items" :key="item.id">
          <DropdownItem :item="item" :index="index" :active="index === activeItem" @select="onItemSelect" />
        </li>
      </ul>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  $width: px(302);
  $padding: px(16);

  .dropdown {
    position: relative;

    z-index: $layer-foreground;
    cursor: pointer;

    color: $black;

    min-width: $width;
    font-size: px(16);

    margin-left: -$padding;

    &.black {
      label {
        &.main {
          // @include heroic-bold(px(40)); currently using news dropdown styling
        }
      }
      &:not(.active) {
        color: $white;
      }
    }

    &.active {
      z-index: 1000;
      label {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .items {
      user-select: none;

      position: absolute;
      top: 0;

      min-width: $width;

      background-color: $white;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);

      padding-bottom: px(16);
    }

    label {
      display: block;
      @include industry-demi(px(16));

      user-select: none;

      padding: px(12) $padding px(12);

      cursor: pointer;

      display: flex;
      align-items: center;

      gap: px(10);

      &.main {
        @include fade('color', 0.22s);
        :deep(svg) {
          path {
            @include fade('fill', 0.22s);
          }
        }

        @include hover {
          @include fade('color', 0.08s);
          color: $red;
        }
      }

      :deep(svg) {
        width: px(9);
        margin-top: px(-3);

        path {
          fill: currentColor;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;

      align-items: stretch;

      li {
        &:not(:last-child) {
          border-bottom: px(1) solid $grey-5;
        }
      }
    }

    &.right {
      margin-right: -$padding;
      label {
        text-align: right;
        justify-content: flex-end;
      }

      .items {
        right: 0;
      }
    }

    @include break-small {
      min-width: 50%;
    }

    @include break-phone {
      &.wideMobile {
        &.active {
          margin-bottom: px(38);

          > label {
            display: none;
          }
        }

        .items {
          position: relative;
          width: calc(100vw - 2 * $margin-phone-wide);
        }
      }
    }
  }
</style>
