import { defineStore } from 'pinia';
import { collectionList } from '~/pinkyellow/satanic-api';

export const useUpcomingEventsStore = defineStore('upcoming-event', {
  state: () => ({
    events: [],
  }),

  getters: {
    getEvents(state) {
      return state.events;
    },
  },

  actions: {
    async fetch() {
      let events = await collectionList({
        collection: 'events',
        sorting: [{ field: 'starts_at', direction: 'asc' }],
        filters: [{ field: 'ends_at', condition: 'gt', value: new Date().toISOString().slice(0, 10) }],
      });

      if (!events) {
        this.events = [];
        return;
      }

      events.data.forEach((event) => {
        this.events.push(event);
      });
    },
  },
});
