<script setup>
  const { fight } = defineProps({ fight: Object });

  // --

  const multiple = false;

  const formattedDate = useDate().format(fight.event.starts_at);


  const fightTitle = fight.weight_class?.title + ' ' + fight.fight_type?.title;

  const wow = fight.wow || '';

</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation>
    <EventCard class="event-card" :class="{ multiple }" :event="fight.event" @fightSelect="onFightSelect">
      <template v-slot:left>
        <div class="left-wrapper">
          <NuxtLink :to="fight.event.url" class="heading">
            <EventLogo :event="fight.event" />
            <span class="info">{{ formattedDate }}</span>
          </NuxtLink>
          <div class="title">
            <h2>{{ fightTitle }}</h2>
            <span v-if="wow" class="wow" :class="{ active: wow }">{{ wow }}</span>
          </div>
          <ButtonGroup
            v-if="fight.event.buttons.length"
            class="buttons"
            :buttons="fight.event.buttons"
            :event="fight.event"
            center
            stackMobile
          />
        </div>
      </template>
      <template v-slot:right>
        <PastEventFighters :whiteCorner="fight.white_corner" :blackCorner="fight.black_corner" :whiteCornerWin="fight.white_corner_win" :blackCornerWin="fight.black_corner_win" :large="!multiple" />
      </template>
      <div class="buttons-bottom" v-if="fight.event.buttons.length">
        <ButtonGroup
          v-if="fight.event.buttons.length"
          class="buttons"
          :buttons="fight.event.buttons"
          :event="fight.event"
          center
          stackMobile
        />
      </div>
    </EventCard>
  </ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .event-card {
    overflow: hidden;

    height: px(420);
    max-height: px(420);

    .fighters {
      width: px(488);
      max-width: 40vw;
    }

    &.multiple {
      height: px(448);
      max-height: px(448);

      .fighters {
        width: px(412);
      }
    }

    padding-top: 0;
    padding-bottom: 0;

    &:not(.multiple) {
      :deep(.right-side) {
        > div {
          justify-content: center;
        }
        .bottom {
          display: none;
        }
      }
    }

    .left-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 32px 0 36px;
      max-width: px(390);

      h2 {
        @include title-s;
        text-align: center;
      }

      .wow {
        display: flex;
        align-items: center;

        background-color: $white;
        color: $black;

        margin-top: px(5);

        @include industry-demi(px(12));

        padding: px(1) px(4) 0;
        height: px(16);

        border-radius: px(1);
        visibility: hidden;

        &.active {
          visibility: visible;
        }
      }
    }

    &.gold {
      h2 {
        @include gold-text;
      }
    }

    :deep(.left-side) {
      width: 100%;

      padding: px(90) px(32);
      justify-content: center;

      .heading {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          margin-top: px(4);
          @include title-xxs;
          font-size: px(12);
        }
      }

      @include break-small {
        .heading {
          .info {
            margin-top: px(2);
          }
        }
        .buttons {
          display: none;
        }
      }
    }

    :deep(.right-side) {
      width: 100%;
      padding-top: px(48);
      padding-bottom: px(42);

      padding-right: px(32);

      > div {
        overflow: visible;

        @include break-medium {
          justify-content: center;
        }
      }

      .bottom {
        height: auto;
      }
    }

    .buttons {
      @include break-min-small {
        max-width: 100%;

        @include break-large {
          width: px(276);
        }
      }
    }

    .buttons-bottom {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0 px(16);

      z-index: 4;

      .buttons {
        margin-top: px(28);
      }

      @include break-min-small {
        display: none;
      }
    }

    @include break-small {
      height: auto;
      max-height: none;

      &.multiple {
        height: auto;
        max-height: none;
      }

      padding: px(42) 0 px(28);

      .title {
        max-width: none;
      }

      :deep(.left-side) {
        padding: 0;

        > div {
          width: 100%;
          align-items: stretch;
        }

        .title {
          padding: px(28) px(16) 0;
          margin-top: px(24);
          margin-bottom: px(32);
          border-top: px(1) solid $black-5;
        }
      }

      :deep(.right-side) {
        padding: 0;

        .fighters {
          width: 100%;
          max-width: 100%;

          border-left: 0;
          border-right: 0;
        }

        .bottom {
          padding: px(20) px(16) 0;
        }
      }
    }
  }
</style>
