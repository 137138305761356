<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <WatchHero v-if="page.events.length" :events="page.events">{{ page.title }}</WatchHero>
  <FastChannels :firstSection="!page.events.length" id="fast-channels">Fast channels</FastChannels>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
