<script setup>
  const input = ref();

  // important: has to be provided by parent component
  const searchQuery = inject('searchQuery');

  const active = ref(false);
  function onToggle() {
    active.value = !active.value;

    if (active.value) {
      setTimeout(() => {
        input.value.focus();
      }, 1.0);
    }
  }

  onOutsideClick((e) => {
    if (e.target === input.value) return;
    active.value = false;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div>
    <input ref="input" v-if="active" type="text" v-model="searchQuery" />
    <button @click.stop="onToggle" aria-label="search">
      <span v-if="!active"><IconSearch /></span>
      <span v-else class="close"><IconClose /></span>
    </button>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    position: absolute;
    width: 100%;
    height: px(80);

    margin-top: px(-16);

    display: flex;
    justify-content: flex-end;

    input {
      position: absolute;

      width: 100%;
      height: 100%;

      // background: black;
      z-index: $layer-foreground;

      border: none;
      outline: none;

      border-radius: px(2);

      font-size: px(20);
      padding: 0 px(32);
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      @include hitbox;

      z-index: $layer-foreground;

      span {
        &:deep(path) {
          @include fade('fill', 0.37s);
        }

        &.close {
          margin-right: px(24);
          &:deep(path) {
            fill: $black;
          }
        }
      }

      @include hover {
        span {
          &:deep(path) {
            @include fade('fill', 0.15s);
            fill: $grey-3;
          }

          &.close {
            &:deep(path) {
              fill: $red;
            }
          }
        }
      }
    }

    @include break-small {
      height: px(64);

      input {
        padding: 0 px(20);
      }

      button {
        span {
          &.close {
            margin-right: px(16);
          }
        }
      }
    }
  }
</style>
