<script setup>
  const { card, inTournament } = defineProps({ card: Object, inTournament: Boolean });
  const VISIBLE_STATS_WHEN_CLOSED = 1;

  const { expandCard, collapseCard, isExpanded } = useEventDetails();
  const open = ref(isExpanded(card.id));

  const fightTitle = card.weight_class?.title + ' ' + card.fight_type?.title;

  function onToggleClick() {
    open.value = !open.value;

    if (open.value) expandCard(card.id);
    else collapseCard(card.id);
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="fight" :class="{ open, inTournament }">
    <div class="title top-title">
      <h4>
        {{ fightTitle }}
      </h4>
      <span v-if="card.wow" class="wow">{{ card.wow }}</span>
    </div>
    <div class="grid fighters" :class="{ tbd: !(card.white_corner && card.black_corner) }">
      <ClickableCard :to="card.white_corner ? card.white_corner.url : ''">
        <FighterThumbnailFight
          :fighter="card.white_corner"
          :forceActive="!card.white_corner_win && !card.black_corner_win"
          :winner="card.white_corner_win"
          inWhiteCorner
          extraLargeLabel
        />
      </ClickableCard>
      <div class="title bottom-title">
        <h4>
          {{ fightTitle }}
        </h4>
        <span v-if="card.wow" class="wow">{{ card.wow }}</span>
      </div>
      <ClickableCard :to="card.black_corner ? card.black_corner.url : ''">
        <FighterThumbnailFight
          :fighter="card.black_corner"
          :forceActive="!card.white_corner_win && !card.black_corner_win"
          :winner="card.black_corner_win"
          extraLargeLabel
        />
      </ClickableCard>

      <div class="name">
        <h5>{{ card.white_corner ? card.white_corner.last_name : 'TBD' }}</h5>
      </div>
      <span class="vs">vs</span>
      <div class="name">
        <h5>{{ card.black_corner ? card.black_corner.last_name : 'TBD' }}</h5>
      </div>
    </div>
    <template v-if="card.white_corner && card.black_corner">
      <div class="grid stats">
        <template v-for="(stat, name, index) in card.fight_stats">
          <template v-if="open || index < VISIBLE_STATS_WHEN_CLOSED">
            <span class="stat"
              ><span>{{ stat.white_corner.value || '-' }}</span></span
            >
            <span class="stat"
              ><span>{{ stat.title }}</span></span
            >
            <span class="stat"
              ><span>{{ stat.black_corner.value || '-' }}</span></span
            >
          </template>
        </template>
      </div>
      <button class="toggle" @click="onToggleClick" aria-label="expand">
        <span><IconArrowVertical /></span>
      </button>
    </template>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  $toggleHeight: 56;
  $toggleHeightTournament: 48;

  .fight {
    &.inTournament {
      position: relative;

      @include break-small {
        .title {
          display: none !important;
        }
      }

      .grid {
        &.stats {
          margin-bottom: px($toggleHeightTournament - 1);
        }
      }

      .toggle {
        height: px($toggleHeightTournament);
      }

      &.open {
        .grid {
          &.stats {
            margin-bottom: px($toggleHeightTournament - 1);
          }
        }
      }
    }

    &:not(.inTournament) {
      .grid {
        &.stats {
          margin-bottom: px($toggleHeight - 1);
        }
      }

      .title {
        h4 {
          @include gold-text;
        }
      }
    }

    &.open {
      .grid {
        &.stats {
          margin-bottom: px(32 + $toggleHeight);
        }
      }

      .toggle {
        :deep(svg) {
          transform: scaleY(-1);
        }
      }
    }

    .grid {
      border: px(1) solid $black-5;

      display: grid;
      grid-template-columns: 22vw auto 22vw;

      @include break-medium {
        grid-template-columns: 24vw auto 24vw;
      }

      @include break-small {
        grid-template-columns: 25% auto 25%;
      }

      &.fighters {
        position: relative;

        @include break-small {
          grid-template-columns: 1fr 1fr;

          &:not(.tbd) {
            border-bottom: none;
          }

          .title {
            display: none;
          }

          .vs {
            display: none;
          }

          &:after {
            @include heroic-bold(px(20));

            position: absolute;
            pointer-events: none;

            left: 50%;
            top: calc(50% - px(30)); // half .name bar

            transform: translate(-50%, -50%);
            background-color: $black;

            padding: px(5) px(3) px(2) px(4);

            @include break-small {
              content: 'vs';
            }
          }
        }
      }

      > * {
        display: flex;
        justify-content: center;
        align-items: center;

        border-top: px(1) solid $black-5;

        &:nth-child(-n + 3) {
          border-top: none;
        }

        border-right: px(1) solid $black-5;

        &:nth-child(3n) {
          border-right: none;
        }
      }

      &.stats {
        margin-bottom: px($toggleHeight - 1);

        > * {
          // odd rows
          &:nth-child(6n + 4),
          &:nth-child(6n + 5),
          &:nth-child(6n + 6) {
            background-color: $black-3;
          }

          // center items
          &:nth-child(3n + 2) {
            color: $grey-2;
          }
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: px(10);

      padding: 0 px(32);

      h4 {
        @include title-s;

        text-align: center;
      }

      .wow {
        display: flex;
        align-items: center;

        background-color: $white;
        color: $black;

        @include industry-demi(px(12));

        padding: px(1) px(4) 0;
        height: px(16);

        border-radius: px(1);
      }

      &.top-title {
        display: none;
        margin-bottom: px(24);

        h4 {
          font-size: px(32);
        }
      }
    }

    .stat {
      height: px(48);
      font-size: px(14);

      span {
        text-align: center;
      }
    }

    .name {
      h5 {
        margin-top: 0.08em;
      }

      height: px(72);
      // border-top: px(1) solid $black-5;

      @include heroic-bold(px(48));

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .vs {
      @include heroic-bold(px(32));
    }

    @include break-large {
      .name {
        font-size: px(36);
      }
    }

    @include break-medium {
      .name {
        font-size: px(32);
      }
    }

    @include break-small {
      .name {
      }
    }

    @include break-phone {
      .name {
        height: px(60);
        font-size: px(32);
      }
    }

    .toggle {
      position: absolute;
      background-color: $black-5;

      width: 100%;
      height: px($toggleHeight);

      bottom: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        width: px(30);
        height: px(30);

        border-radius: 100%;

        border: px(1) solid $grey-1;

        display: flex;
        justify-content: center;
        align-items: center;

        :deep(svg) {
          width: px(9);

          path {
            fill: $white;
          }
        }

        @include fade('border-color', 0.37s);
      }

      @include fade('background-color', 0.37s);
      @include hover {
        @include fade('background-color', 0.15s);
        background-color: #2a2a2a;

        span {
          @include fade('border-color', 0.15s);
          border-color: $grey-2;
        }
      }
    }

    @include break-small {
      .title {
        gap: px(8);

        &.top-title {
          display: flex;
        }
      }

      .name {
        height: px(60);
      }

      .grid {
        border-left: none;
        border-right: none;

        .stat {
          font-size: px(11);
          border-right: none;

          height: px(60);

          // left items
          &:nth-child(3n + 1) {
            justify-content: flex-end;

            span {
              transform: translateX(50%);
            }
          }

          // right items
          &:nth-child(3n + 3) {
            justify-content: flex-start;

            span {
              transform: translateX(-50%);
            }
          }
        }
      }

      &:not(.inTournament) {
        .toggle {
          @include break-small {
            bottom: -px($toggleHeight);
          }
        }
        &.open {
          .grid {
            &.stats {
              margin-bottom: px($toggleHeight - 1);
            }
          }
        }
      }
    }
  }
</style>
