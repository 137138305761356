<script setup>
  const { items, slide, narrowCards } = defineProps({ items: Array, slide: Object, narrowCards: Boolean });

  // --

  const cursor = ref();

  function activate() {
    cursor.value.activate();
  }

  function deactivate() {
    cursor.value.deactivate();
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide @mouseenter="activate" @mouseleave="deactivate">
    <BigTitle>
      <slot />
    </BigTitle>

    <Slider :items="items" :slide="slide" :narrowCards="narrowCards" />

    <SliderCursor ref="cursor" class="cursor" />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    background-color: $black-3;
    padding-top: px(90);
    padding-bottom: px(128);
  }
</style>
