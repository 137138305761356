export default (ranking, weightClass = '') => {
  if (!ranking || ranking.value === 'unranked' || ranking.value === null) {
    return '';
  }

  if (!Number.isNaN(Number(ranking.value))) {
    return `Rank ${ranking.label}`;
  }

  if (weightClass) {
    return weightClass + ' ' + ranking.label;
  }

  return ranking.label;
};
