<script setup>
  const { data } = defineProps(['data']);
  const { embed, thumbnail, caption } = data;

  // --

  const embedRef = ref();
  let frame;

  let provider;
  let autoplay;

  onMounted(() => {
    const iframe = embedRef.value.querySelector('iframe');

    provider = iframe.dataset.provider;
    autoplay = iframe.dataset.autoplay;

    frame = iframe.contentWindow;
    autoplay === '1' && play();
  });

  // --

  const playing = ref(false);

  function onThumbClick(e) {
    playing.value || play();
  }

  // --

  function play() {
    playing.value = true;

    if (provider.includes('YouTube')) frame.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    if (provider.includes('Vimeo')) frame.postMessage('{"method":"play"}', '*');
  }

  function pause() {
    if (provider.includes('YouTube')) frame.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    if (provider.includes('Vimeo')) frame.postMessage('{"method":"pause"}', '*');

    playing.value = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide>
    <ShowAnimation fadeOnly>
      <div class="container">
        <div v-show="playing" ref="embedRef" v-html="embed"></div>
        <div v-show="!playing" class="thumb" @click="onThumbClick">
          <Picture v-if="thumbnail" preset="landscape-1280" :image="thumbnail" />
          <PlayButton class="play" v-show="!playing" />
        </div>
      </div>
    </ShowAnimation>
    <ShowAnimation fadeOnly
      ><Caption v-if="caption">{{ caption }}</Caption></ShowAnimation
    >
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: $block-padding-large 0 $block-padding-medium;

    @include break-medium {
      padding: $block-padding-large 0;
    }

    @include break-small {
      padding: px(40) 0;
    }
  }

  .container {
    position: relative;
    height: 0;

    width: 100%;
    padding-bottom: calc(9 / 16 * 100%); // 56.25%

    cursor: pointer;

    @include hover {
      .play {
        background-color: $red;
        @include fade('background-color', 0.11s);
      }
    }

    .play {
      position: absolute;
      @include fade('background-color', 0.36s);

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    picture {
      position: absolute;
    }

    :deep(iframe) {
      position: absolute;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
