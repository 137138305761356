<script setup>
  const { title, subtitle } = defineProps(['title', 'subtitle']);

  const sub = subtitle.replace(/\*/g, '<span>').replace(/\#/g, '</span>');
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="stat-card">
    <div>
      <h3>{{ title }}</h3>
      <h4 v-html="sub"></h4>
    </div>
    <div class="stat"><slot /></div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .stat-card {
    background-color: $black;

    width: px(302);
    height: px(412);

    padding: px(48) px(38);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: px(32);

    text-align: center;

    h3 {
      font-size: px(32);
    }

    h4 {
      color: $grey-2;
      font-size: px(11);

      margin-top: px(8);

      :deep(span) {
        color: $red;
      }
    }

    @include break-phone {
      width: 100%;
    }
  }

  :deep(.stat) {
    width: 100%;
    height: 100%;

    max-width: px(320);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      @include industry-demi(px(80));
    }
  }
</style>
