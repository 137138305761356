<script setup>
  import { useFightHistoryStore } from '~/stores/fight-history';

  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  const upcomingFight = ref();
  const pastFights = ref({});
  const news = ref({});

  onMounted(async () => {
    const fightStore = useFightHistoryStore();
    await fightStore.fetch(page.id);
    pastFights.value = fightStore.getPastFights;
    upcomingFight.value = fightStore.getUpcomingFight;

    const newsStore = useFighterNewsStore();
    await newsStore.fetch(page.id);
    news.value = newsStore.getNews;
  });

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <FighterHero :fighter="page" />
  <FighterPassport :fighter="page" />
  <UpcomingFight v-if="upcomingFight && upcomingFight.white_corner && upcomingFight.black_corner" :fight="upcomingFight"
    >Upcoming fight</UpcomingFight
  >
  <FighterStats v-if="page.stats && Object.keys(page.stats).length" :stats="page.stats">Statistics</FighterStats>
  <RelatedNews v-if="news.length" :items="news" black>Related news</RelatedNews>
  <FighterBio :fighter="page">About {{ page.first_name }}</FighterBio>
  <PastFights v-if="pastFights.length" :fights="pastFights" :firstSection="true">Fighter History</PastFights>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
