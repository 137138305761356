<script setup>
  import moment from 'moment';

  const { fighter } = defineProps(['fighter']);

  const age = moment().diff(moment(fighter.date_of_birth), 'years');
  const variation = fighter.passport_image?.basename.includes('passport_image');
  const weightClass = useTaxonomyStore().getTerm('weight_classes', fighter.weight_class);
  const ranking = useFighterRanking(fighter.ranking);

  let nationality = '';
  fighter.nationality.forEach(({ label }, index) => (nationality += index === 0 ? label : `, ${label}`));
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide :class="{ variation }">
    <ShowAnimation>
      <FighterPassportStats :fighter="fighter" />
    </ShowAnimation>
    <h3 class="heading-top" v-if="fighter.nickname">{{ fighter.nickname }}</h3>
    <div class="passport">
      <ShowAnimation class="image-container">
        <img
          class="front"
          v-if="fighter.passport_image"
          :src="fighter.passport_image.url"
          :alt="fighter.title"
          width="560"
          height="680"
        />
        <!-- <Picture v-if="fighter.passport_image" preset="passport" :image="fighter.passport_image" /> -->
      </ShowAnimation>
      <div>
        <ShowAnimation>
          <h3 v-if="fighter.nickname">{{ fighter.nickname }}</h3>
        </ShowAnimation>

        <ul>
          <ShowAnimation v-if="weightClass">
            <li><label>Division</label>{{ fighter.id === 83 ? 'Lightweight, ' : ''}}{{ weightClass }}</li>
          </ShowAnimation>
          <ShowAnimation v-if="fighter.height">
            <li><label>Height</label>{{ fighter.height }}m</li>
          </ShowAnimation>
          <ShowAnimation v-if="ranking || fighter.retired">
            <li><label>Ranking</label>{{ fighter.id === 83 ? 'Champion, ' : ''}}{{ fighter.retired ? 'Retired' : ranking }}</li>
          </ShowAnimation>
          <ShowAnimation v-if="fighter.weight">
            <li><label>Weight</label>{{ fighter.weight }}kg</li>
          </ShowAnimation>
          <ShowAnimation v-if="fighter.date_of_birth">
            <li><label>Age</label>{{ age }}</li>
          </ShowAnimation>
          <ShowAnimation v-if="nationality">
            <li><label>Nationality</label>{{ nationality }}</li>
          </ShowAnimation>
        </ul>
        <ShowAnimation v-if="fighter.socials.length" class="socials"
          ><Socials :socials="fighter.socials"
        /></ShowAnimation>
      </div>
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    background-color: $black-3;
    // padding-top: px(120);

    h3 {
      @include title-s;
      margin-bottom: px(56);

      &.heading-top {
        font-size: px(32);
        text-align: center;

        padding-top: px(64);
        margin-bottom: px(32);

        @include break-min-small {
          display: none;
        }
      }
    }

    .socials {
      margin-top: px(80);
      position: relative;
      z-index: 1000;
    }

    &.variation {
      .passport {
        .image-container {
          width: 32vw;
          margin-left: 5.5vw;
          margin-top: px(32);

          border: px(1) solid $grey-0;

          margin-right: px(48);
        }

        @include break-small {
          ul {
            width: 100%;
            padding: px(64) px(32) 0;
          }
          .image-container {
            width: 100%;
            max-width: 50vw;

            margin-right: 0;

            margin-left: 0;
            align-self: center;
          }

          flex-direction: column;
          align-items: center;
        }
      }
    }

    &:not(.variation) {
      @include break-min-small {
        .passport {
          .image-container {
            width: 40vw;

            margin-top: px(-96);
            margin-left: px(-48);
          }
        }
      }
    }

    .passport {
      border-top: px(1) solid $grey-0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-top: px(64);

      .image-container {
        height: auto;
        flex-shrink: 0;
      }

      > div {
        width: 100%;
      }

      ul {
        z-index: 1;
        position: relative;

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 8vw;
        row-gap: px(18);

        li {
          display: flex;
          flex-direction: column;

          @include roboto-regular(px(24));
          border-bottom: px(1) solid $grey-0;

          padding-bottom: px(11);
        }

        label {
          @include industry-demi(px(12));
          opacity: 0.5;
        }
      }
    }

    &:not(.variation) {
      &:after {
        content: '';

        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 20vw;

        background: linear-gradient(0deg, $black-3 30%, rgba(195, 0, 0, 0) 70%);
      }
    }

    @include break-large {
      h3 {
        @include break-min-medium {
          font-size: px(42);
        }
      }
      .passport {
        ul {
          column-gap: 4vw;

          li {
            font-size: px(20);

            label {
            }
          }
        }
      }
    }

    @include break-medium {
      padding-top: px(48);
    }

    @include break-small {
      .passport {
        border-top: 0;
        padding-top: 0;

        h3 {
          display: none;
        }

        .image-container {
          margin-left: calc(-30vw - $margin-phone-wide);
          width: 100vw;
          max-width: px(600);

          margin-right: -12vw;

          margin-top: 0;

          align-self: flex-end;
        }

        ul {
          width: 50vw;

          grid-template-columns: 1fr;

          padding-top: px(32);

          li {
            font-size: px(16);
            padding-right: $margin-phone-wide;

            label {
              font-size: px(10);
            }
          }
        }
      }
    }

    @include break-phone {
      .socials {
        :deep(ul) {
          justify-content: flex-start;
        }
      }
      .passport {
        .image-container {
          margin-left: calc(-45vw - $margin-phone-wide);
          width: 120vw;
        }

        ul {
          li {
            font-size: px(14);
          }
        }
      }
    }
  }
</style>
