const selectedProgramIndex = ref(0);
const expandedCards = ref([]);

export default () => {
  function findCard(id) {
    let card = expandedCards.value.find((card) => card.id === id);

    if (!card) {
      card = { id, expanded: false };
      expandedCards.value.push(card);
    }

    return card;
  }
  function expandCard(id) {
    findCard(id).expanded = true;
  }

  function collapseCard(id) {
    findCard(id).expanded = false;
  }

  function isExpanded(id) {
    return findCard(id).expanded;
  }

  return { selectedProgramIndex, expandCard, collapseCard, isExpanded };
};
