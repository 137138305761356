<script setup>
  const { event } = defineProps(['event']);

  // --

  const half = Math.ceil(event.tournament_fighter_names.length * 0.5);

  const left = event.tournament_fighter_names.slice(0, half);
  const right = event.tournament_fighter_names.slice(half);
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="predraw">
    <div class="content">
      <h3>
        <span>{{ event.size.label }}</span> {{ event.tournament_title }}
      </h3>
      <div class="fighter-list">
        <ul class="left">
          <li v-for="fighter in left">{{ fighter }}</li>
        </ul>
        <ul class="right">
          <li v-for="fighter in right">{{ fighter }}</li>
        </ul>
      </div>
    </div>
    <img :src="useCdn().generateStaticAsset('images/upcoming_predraw_bg.png')" width="676" height="494" />
    <img class="mobile" :src="useCdn().generateStaticAsset('images/upcoming_predraw_bg_mobile.png')" width="332" height="458" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .predraw {
    width: 100%;
    height: 100%;

    img {
      position: absolute;

      &:not(.mobile) {
        top: 0;
        left: px(-150);

        width: auto;
        height: 100%;

        @include break-small {
          display: none;
        }
      }

      &.mobile {
        top: px(50);
        min-width: px(600);

        left: 50%;
        transform: translateX(-50%);

        width: 101%;
        height: auto;

        // min-height: 100%;

        @include break-min-small {
          display: none;
        }
      }
    }

    .content {
      position: relative;
      z-index: $layer-foreground;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 100%;
      gap: px(48);

      h3 {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include heroic-bold(px(32));
        span {
          @include heroic-heavy(px(72));
          margin-bottom: px(6);
        }
      }

      .fighter-list {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        gap: px(32);

        @include heroic-bold(px(32));

        ul {
          display: flex;
          flex-direction: column;
          gap: px(4);

          width: 100%;

          &.left {
            text-align: right;
          }
          li {
          }
        }
      }
    }

    @include break-small {
      .content {
        h3 {
          display: none;
        }

        .fighter-list {
          flex-direction: column;
          align-items: center;
          gap: px(4);

          margin-bottom: px(16);

          ul {
            width: auto;

            &.left,
            &.right {
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
