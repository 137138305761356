<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3764_28850)">
      <path d="M16.4197 16.0488H2.73828V18.7851H16.4197V16.0488Z" fill="url(#paint0_linear_3764_28850)" />
      <path d="M16.4197 16.0488H2.73828V18.7851H16.4197V16.0488Z" fill="url(#paint1_linear_3764_28850)" />
      <path
        d="M17.7858 2.36815C17.0333 2.36815 16.4177 2.98381 16.4177 3.73629C16.4177 4.22882 16.6913 4.65294 17.088 4.88552L14.9537 8.429C14.3928 9.35933 13.0384 9.30461 12.5595 8.33323L10.1789 3.55843C10.6304 3.33953 10.9451 2.88804 10.9451 2.35447C10.9451 1.60199 10.3294 0.986328 9.57697 0.986328C8.82449 0.986328 8.20883 1.60199 8.20883 2.35447C8.20883 2.88804 8.5235 3.33953 8.97499 3.55843L6.59443 8.33323C6.11558 9.30461 4.74744 9.34565 4.20018 8.429L2.06589 4.87184C2.46265 4.63926 2.73628 4.21513 2.73628 3.7226C2.73628 2.97013 2.12061 2.35447 1.36814 2.35447C0.615662 2.35447 0 2.97013 0 3.7226C0 4.47508 0.615662 5.09074 1.36814 5.09074C1.42286 5.09074 1.47759 5.06338 1.53231 5.0497L2.73628 14.6677H16.4177L17.6216 5.0497C17.6216 5.0497 17.7311 5.09074 17.7858 5.09074C18.5383 5.09074 19.1539 4.47508 19.1539 3.7226C19.1539 2.97013 18.5383 2.35447 17.7858 2.35447V2.36815Z"
        fill="url(#paint2_linear_3764_28850)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3764_28850"
        x1="2.73828"
        y1="16.0488"
        x2="16.4197"
        y2="16.0488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EEC99E" />
        <stop offset="1" stop-color="#FFEEC2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3764_28850"
        x1="2.73828"
        y1="16.0488"
        x2="16.4197"
        y2="16.0488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A2A2A2" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3764_28850"
        x1="0"
        y1="0.986328"
        x2="19.1539"
        y2="0.986328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A2A2A2" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <clipPath id="clip0_3764_28850">
        <rect width="20" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(20);
  }
</style>
