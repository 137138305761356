import { defineStore } from 'pinia';
import { collectionList } from '~/pinkyellow/satanic-api';

export const usePastEventsStore = defineStore('past-event', {
  state: () => ({
    events: [],
    limit: 4,
    page: 1,
    meta: null,
  }),

  getters: {
    getEvents(state) {
      return state.events;
    },

    canPaginate(state) {
      return state.meta && state.meta.current_page < state.meta.last_page;
    },
  },

  actions: {
    async fetch() {
      let events = await collectionList({
        collection: 'events',
        limit: this.limit,
        page: this.page,
        sorting: [{ field: 'starts_at' }],
        filters: [{ field: 'ends_at', condition: 'lt', value: new Date().toISOString().slice(0, 10) }],
      });

      if (!events) {
        this.events = [];
        return;
      }

      this.meta = events.meta;

      events.data.forEach((event) => {
        this.events.push(event);
      });

      this.page += 1;
    },
  },
});
