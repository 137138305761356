<script setup>
  import Telescope from '~/pinkyellow/telescope';

  // --
  var playing = false;

  onMounted(() => {
    video.value.onplaying = () => (playing = true);
    video.value.onpause = () => (playing = false);
  });

  async function play() {
    if (video.value.paused && !playing) {
      video.value.muted = true;
      return video.value.play();
    }
  }

  function pause() {
    if (!video.value.paused && playing) {
      video.value.pause();
    }
  }

  // --
  const video = ref();

  onMounted(() => {
    Telescope.detect(video.value, {
      visible: () => {
        play();
        // video.value.play();
      },
      partial: () => {
        play();
        // video.value.play();
      },
      hidden: () => {
        pause();
        // video.value.pause();
      },
    });
  });

  onBeforeUnmount(() => {
    video.value && Telescope.ignore(video.value);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <video ref="video" muted loop playsinline><slot /></video>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
