<script setup>
  const { page } = defineProps(['page']);
  const events = ref({});

  useTheme().setTheme('dark');

  const upcomingEventsStore = useUpcomingEventsStore();
  const pastEventsStore = usePastEventsStore();

  const upcomingEvents = upcomingEventsStore.getEvents;

  if (upcomingEvents.length < 1) {
    upcomingEventsStore.fetch();
  }
  pastEventsStore.fetch();

  const pastEvents = pastEventsStore.getEvents;

  const canPaginate = ref(false);
  const loading = ref();

  watch(pastEvents, () => {
    canPaginate.value = pastEventsStore.canPaginate;

    loading.value = false;
    document.body.style.cursor = 'auto';
  });

  function loadMore() {
    document.body.style.cursor = 'progress';
    loading.value = true;

    pastEventsStore.fetch();
  }

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <UpcomingEvents v-if="upcomingEvents.length" :events="upcomingEvents">{{ page.title }}</UpcomingEvents>
  <PastEvents v-if="pastEvents.length" :events="pastEvents" :firstSection="!upcomingEvents.length"
    >Past events
  </PastEvents>
  <LoadMore v-if="canPaginate" @loadMore="loadMore" :disabled="loading">Load more</LoadMore>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
