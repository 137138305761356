<script setup>
  const { event } = defineProps({ event: Object, small: Boolean });

  const formattedDate = useDate().format(event.starts_at);
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="info" :class="{ small }">
    <span>{{ formattedDate }}</span>
    <Bullet :small="small" />
    <span>{{ event.venue }}</span>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .info {
    display: flex;
    align-items: center;
    gap: 0.35em;

    @include title-xxs;

    &.small {
      font-size: px(12);
    }
  }
</style>
