export default (callback) => {
  onMounted(() => {
    window.addEventListener('mousemove', onMove);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('mousemove', onMove);
  });

  function onMove(e) {
    callback(e.clientX, e.clientY);
  }
};
