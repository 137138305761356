<script setup>
  const { fight } = defineProps(['fight']);

  const fightTitle = ref();
  const whiteCornerRanking = ref(null);
  const blackCornerRanking = ref(null);

  fightTitle.value = fight.weight_class?.title + ' ' + fight.fight_type?.title;
  whiteCornerRanking.value = useFighterRanking(fight.white_corner.ranking);
  blackCornerRanking.value = useFighterRanking(fight.black_corner.ranking);

  function chooseFighterImage(fighter, side) {
    if (fighter[side]?.url) {
      return fighter[side]?.url;
    }

    return useCdn().generateStaticAsset('images/fighter_silhouette_' + side + '.png');
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <Card :color="fight.event.color">
    <Foreground>
      <EventLogo :event="fight.event" />
      <h4>{{ useDate().format(fight.event.starts_at) }}</h4>
      <h3>{{ fightTitle }}</h3>
      <ButtonGroup
        v-if="fight.event.buttons.length"
        class="buttons buttons-top"
        :buttons="fight.event.buttons"
        :event="fight.event"
      />
      <FightTicker class="ticker-tape" :red="fight.event.color === '#C30000'">
        <span>
          <b>{{ fight.white_corner?.last_name ?? 'TBD' }}</b
          >&nbsp;{{ whiteCornerRanking }}<span>vs</span>&nbsp; <b>{{ fight.black_corner?.last_name ?? 'TBD' }}</b
          >&nbsp;{{ blackCornerRanking }}
        </span>
      </FightTicker>
    </Foreground>
    <div class="fighters">
      <img
        class="front"
        :src="chooseFighterImage(fight.white_corner, 'left_image')"
        :alt="fight.white_corner.last_name"
        width="530"
        height="452"
      />
      <img
        class="front"
        :src="chooseFighterImage(fight.black_corner, 'right_image')"
        :alt="fight.black_corner.last_name"
        width="530"
        height="452"
      />
    </div>
  </Card>
  <div class="buttons-bottom">
    <ButtonGroup
      v-if="fight.event.buttons.length"
      class="buttons"
      :buttons="fight.event.buttons"
      :event="fight.event"
      center
    />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .card {
    position: relative;
    background-color: $black;

    overflow: hidden;

    .foreground {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding-top: px(88);

      @include break-small {
        padding-top: px(40);
      }
    }

    h3 {
      @include title-s;
      @include gold-text;

      text-align: center;

      max-width: px(360);
      margin-top: px(28);

      @include break-medium {
        font-size: px(40);
        max-width: px(220);

        margin-top: px(58);
      }
    }

    h4 {
      @include industry-demi(px(12));

      @include break-small {
        font-size: px(10);
      }
    }

    .buttons {
      &.buttons-top {
        margin-top: px(36);
        @include break-small {
          display: none;
        }
      }
    }

    .ticker-tape {
      width: 100%;
      margin-top: px(96);

      @include break-small {
        margin-top: px(84);
      }
    }

    .fighters {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 115%;

      gap: 10%;

      // padding-top: px(16);

      display: flex;
      justify-content: space-between;

      @include break-small {
        justify-content: center;
        align-items: flex-end;

        padding-top: px(64);
      }

      img {
        object-fit: contain;

        @include break-phone {
          &:nth-child(1) {
            margin-right: px(-20);
          }

          &:nth-child(2) {
            margin-left: px(-20);
          }
        }
      }
    }
  }

  .buttons-bottom {
    @include grid;

    margin-top: px(24);

    .buttons {
      width: 100%;
      @include column(2, 11);
    }

    @include break-min-small {
      display: none;
    }
  }
</style>
