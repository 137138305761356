<script setup>
  const { to } = defineProps(['to']);
  const target = useLinkTarget(to);
</script>

<!----------------------------------------------------------------------------->

<template>
  <NuxtLink :to="to" :target="target" class="card"><slot /></NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    display: block;
    cursor: pointer;
  }
</style>
