<script setup>
  const { small } = defineProps({ small: Boolean, center: Boolean });

  // --

  function onResize(containerWidth, whiteCornerWidth, vsWidth, blackCornerWidth, fontSize) {
    const LETTER_SPACING_OFFSET = 1.03;

    const availableWidth = containerWidth - vsWidth;
    const currentWidth = whiteCornerWidth + blackCornerWidth * LETTER_SPACING_OFFSET;

    return (availableWidth / currentWidth) * fontSize;
  }
</script>

<template>
  <span :class="{ center }">
    <FightTitle :small="small" :resize="onResize">
      <template v-slot:whiteCorner><slot name="whiteCorner" /></template>
      <template v-slot:blackCorner><slot name="blackCorner" /></template>
    </FightTitle>
  </span>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  span {
    display: flex;
    &.center {
      justify-content: center;
    }

    :deep(.container) {
      height: 0.7em; // 185px;
      line-height: 0.85em; // 225px;

      .vs {
        padding: 0 0.667em;

        flex-direction: column;
        gap: 0.25em;

        margin-left: 0.15em;

        span {
        }

        &:before,
        &:after {
          width: px(2);
          height: 100%;
        }
      }

      &.small {
        font-size: px(96);

        .vs {
          padding: 0 0.6em;

          font-size: px(28);
          gap: 0.16em;

          margin-left: 0.055em;
        }
      }

      @include break-small {
        display: none;
      }
    }
  }
</style>
