<script setup>
  const { item } = defineProps(['item']);

  const difference = Math.abs(item.data.valueA - item.data.valueB).toFixed(1);

  let ratioLeft = 1.0;
  let ratioRight = 1.0;

  if (item.data.valueA < item.data.valueB) {
    ratioLeft = item.data.valueA / item.data.valueB;
  } else {
    ratioRight = item.data.valueB / item.data.valueA;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <FighterStatCard
    :title="item.title"
    :subtitle="item.subtitle"
    :style="{ '--ratioLeft': ratioLeft, '--ratioRight': ratioRight }"
  >
    <h5>{{ difference }}</h5>
    <div class="bar-container">
      <div class="bar" />
      <div class="bar" />
    </div>
  </FighterStatCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  :deep(.stat) {
    position: relative;
    max-width: px(200);

    h5 {
      margin-top: px(-72);
    }

    .bar-container {
      position: absolute;

      bottom: px(-48); // stat card bottom padding
      left: 50%;

      transform: translateX(-50%);

      display: flex;
    }

    .bar {
      width: px(12);
      height: px(120);

      background-color: $red;
      transform-origin: center bottom;
      transform: scaleY(var(--ratioLeft));

      &:nth-child(2) {
        background-color: $grey-4;
        transform-origin: center bottom;
        transform: scaleY(var(--ratioRight));
      }
    }
  }
</style>
