<script setup>
  const {} = defineProps(['question', 'body']);

  const open = ref(false);

  function onButtonClick() {
    open.value = !open.value;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation
    ><Text :class="{ open }">
      <button @click="onButtonClick" aria-label="expand question">
        <h3>{{ question }}</h3>
        <IconArrowVertical />
      </button>
      <div v-show="open" v-html="body" class="body"></div> </Text
  ></ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  h3 {
    text-align: left;
    margin-top: -0.1em;

    margin-bottom: 0 !important;
    margin-right: px(20);

    color: $black;
  }

  button {
    width: 100%;
    padding: px(32) 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: $black;

    svg {
      flex-shrink: 0;
      margin-top: px(-1);

      :deep(path) {
        fill: currentColor;
      }
    }

    @include fade('color', 1.08s);
    @include hover {
      @include fade('color', 0.08s);
      color: $red;
    }

    .open & {
      svg {
        transform: rotate(180deg);
      }
    }

    border-bottom: px(1) solid transparent;
    &:not(.open &) {
      border-color: $grey-4;
    }
  }

  .body {
    font-size: px(14);
    padding-bottom: px(52);

    border-bottom: px(1) solid transparent;
    .open & {
      border-color: $grey-4;
    }
  }
</style>
