<script setup>
  const { data } = defineProps(['data']);

  const no_margin = ref(data.no_margin);
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap :class="{ no_margin }">
    <WrapCenter>
      <ShowAnimation fadeOnly><CtaButton :data="data" :no_margin="no_margin" /></ShowAnimation>
    </WrapCenter>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    &.no_margin {
      padding-top: 0;
    }
  }
</style>
