export default (item, size = 'thumb') => {
  switch (item.blueprint.handle) {
    case 'article':
      return size === 'large' ? item.cover_image : item.thumbnail;
    case 'youtube':
      return item.thumbnail;
    case 'video':
      return item.video.thumbnail;
  }
};
