<script setup>
  const container = ref();

  let cursorX = ref(0.0);
  let cursorY = ref(0.0);

  let targetCursorX = 0.0;
  let targetCursorY = 0.0;

  let containerTop = 0.0;
  let containerLeft = 0.0;

  let containerCenterX = 0.0;
  let containerCenterY = 0.0;

  // --

  onResize(() => {
    const rect = container.value.getBoundingClientRect();

    containerCenterX = rect.width * 0.5 - rect.left;
    containerCenterY = rect.height * 0.5;

    cursorX.value = targetCursorX = containerCenterX;
    cursorY.value = targetCursorY = containerCenterY;
  });

  onScroll(() => {
    const rect = container.value.getBoundingClientRect();

    containerTop = rect.top;
    containerLeft = rect.left;
  });

  onMove((x, y) => {
    if (!active) return;

    targetCursorX = x - containerLeft;
    targetCursorY = y - containerTop;
  });

  onTick(() => {
    cursorX.value += (targetCursorX - cursorX.value) * 0.15;
    cursorY.value += (targetCursorY - cursorY.value) * 0.15;
  });

  // --

  let active = false;

  function activate() {
    active = true;
  }

  function deactivate() {
    active = false;
    targetCursorX = containerCenterX;
    targetCursorY = containerCenterY;
  }

  defineExpose({ activate, deactivate });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="container" class="container">
    <div :style="{ transform: `translate3d(${cursorX}px, ${cursorY}px, 0.0)` }">
      <span><IconSliderCursor /></span>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: 1;

    pointer-events: none;

    div {
      span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: px(100);
        height: px(100);

        background-color: $black-2;
        border-radius: 100%;

        transform: translate(-50%, -50%);
      }
    }

    @include touch {
      display: none;
    }

    @include break-small {
      display: none;
    }
  }
</style>
