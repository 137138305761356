<script setup>
  import BlockGenerator from '~/components/generators/BlockGenerator.vue';

  const { fighter } = defineProps(['fighter']);
  const { content } = fighter;
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="fighter-bio" v-if="content && content.length">
    <Section autowrap wide>
      <BigTitle class="title">
        <slot />
      </BigTitle>
    </Section>
    <BlockGenerator :content="content"></BlockGenerator>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .fighter-bio {
    padding: px(64) 0 px(72);

    background-color: $white;
    color: $black;

    .title {
      :deep(h1),
      :deep(h2) {
        color: $black;
        margin-bottom: 0;
      }
    }
  }
</style>
