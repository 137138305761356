<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <h3><slot /></h3>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  h3 {
    @include title-s;
    text-align: center;

    margin-top: 0.25em;
  }
</style>
