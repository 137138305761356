<script setup>
  const { event } = defineProps({ event: Object, small: Boolean });

  const formattedDate = useDate().format(event.starts_at);
  const time = useDate().formatTime(event.starts_at);
  const timezone = useDate().guessTimezone();
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="info" :class="{ small }">
    <span>{{ formattedDate }}</span>
    <Bullet />
    <span>{{ time }} {{ timezone }}</span>
    <Bullet />
    <span>{{ event.venue }}</span>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .info {
    display: flex;
    align-items: center;
    gap: 0.35em;

    @include title-xxs;
    font-size: px(16);

    @include break-large {
      font-size: px(14);
    }

    @include break-medium {
      font-size: px(12);
    }

    @include break-small {
      font-size: px(10);
    }

    @include break-phone {
      font-size: 2.5vw;
    }
  }
</style>
