<script setup>
  const { data } = defineProps(['data']);
  const { left_column, right_column } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap>
    <ColumnGenerator side="left" :content="left_column" />
    <ColumnGenerator side="right" :content="right_column" />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: $block-padding-extra-large 0;

    @include break-medium {
      padding: $block-padding-large 0 $block-padding-extra-small;
    }

    :deep(.grid) {
      align-items: center;
    }
  }
</style>
