<script setup>
  const { item } = defineProps(['item']);
  const { title, description } = item;
</script>

<!----------------------------------------------------------------------------->

<template>
  <aside>
    <div>
      <NewsMeta :item="item" />
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
    <ArrowButton noAnchor class="button" />
  </aside>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  aside {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    gap: px(16);

    color: $black;

    > * {
      flex-shrink: 0;
    }

    div {
      display: flex;
      flex-direction: column;

      gap: px(16);
    }

    h3 {
      font-size: px(32);
      line-height: 0.875;
    }

    p {
      font-size: px(14);
      opacity: 0.6;
    }

    @include break-small {
      gap: px(44);

      div {
        gap: px(10);
      }

      p {
        font-size: px(12);
      }

      .button {
        display: none;
      }
    }
  }
</style>
