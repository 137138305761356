<script setup>
  const { item } = defineProps(['item']);
  const { title, category, date } = item;

  const formattedDate = useDate().formatNews(date);
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap>
    <GridItem :from="2" :to="9" class="wrapper">
      <BackButton to="/news">Back to news</BackButton>
      <h1>{{ title }}</h1>
      <ul>
        <li v-if="category" class="category">
          <span>{{ category.title }}</span>
        </li>
        <li>
          <span>{{ formattedDate }}</span>
        </li>
      </ul>
    </GridItem>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    @include top-padding;
    padding-bottom: px(4);

    a {
      margin-bottom: px(29);
      margin-left: 0.32em;
    }

    h1 {
      @include title-l;
      margin-bottom: px(21);
    }

    ul {
      user-select: none;

      @include industry-demi(px(10));
      color: $grey-3;

      display: flex;
      gap: px(10);

      li {
        height: px(24);
        padding: 0 px(10);

        display: flex;
        align-items: center;

        span {
          margin-top: 0.15em;
        }

        &.category {
          color: $black;

          border: px(1) solid $grey-1;
          border-radius: px(1);
        }
      }

      @include break-small {
        gap: px(14);
        li {
          height: px(20);
          padding: 0 px(6);
        }
      }
    }

    @include break-min-large {
      h1 {
        max-width: 72vw;
      }
    }

    @include break-medium {
      padding-bottom: 0;
      .wrapper {
        @include column(2, 10);
      }
    }

    @include break-small {
      .wrapper {
        @include column(2, 11);
      }
    }

    @include break-phone {
      padding-bottom: px(18);

      .wrapper {
        @include column(1, 12);
      }
    }
  }
</style>
