<script setup>
  const { fighter } = defineProps(['fighter']);

  function leadingZero(num) {
    return String(num).padStart(2, '0');
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <ul>
    <li>
      <label>Wins</label><span>{{ leadingZero(fighter.wins | 0) }}</span>
    </li>
    <li>
      <label>Losses</label><span>{{ leadingZero(fighter.losses | 0) }}</span>
    </li>
    <li>
      <label>Draws</label><span>{{ leadingZero(fighter.draws | 0) }}</span>
    </li>
    <li>
      <label>KO</label><span>{{ leadingZero(fighter.kos | 0) }}</span>
    </li>
  </ul>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  ul {
    display: flex;
    width: 100%;

    li {
      width: 25%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      label {
        @include industry-demi(px(16));
        opacity: 0.5;
      }

      span {
        @include industry-demi(14vw);

        margin: -0.3em 0 -0.16em;
      }
    }

    @include break-medium {
      li {
        label {
          font-size: px(12);
        }

        span {
          font-size: 11vw;
        }
      }
    }

    @include break-small {
      background-color: $black;
      padding: px(12) 0;
      border-radius: px(2);

      li {
        border-right: px(1) solid $grey-0;
        &:last-child {
          border-right: none;
        }

        label {
          // font-size: px(10);
        }

        span {
          margin-bottom: -0.35em;
        }
      }
    }
  }
</style>
