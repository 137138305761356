<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <img :src="useCdn().generateStaticAsset('images/event_hero_bg.png')" alt="glory" class="spotlights desktop" />
  <img :src="useCdn().generateStaticAsset('images/event_hero_bg_mobile.png')" alt="glory" class="spotlights mobile" />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .spotlights {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    object-fit: cover;
    z-index: -1;

    &.desktop {
      @include break-small {
        display: none;
      }
    }

    &.mobile {
      @include break-min-small {
        display: none;
      }
    }
  }
</style>
