<script setup>
  const taxonomyStore = useTaxonomyStore();

  const { item } = defineProps({ item: Object, white: Boolean });
  const { category, date } = item;

  const categoryTitle = category.title || taxonomyStore.getTerm('categories', category);

  const formattedDate = useDate().formatNews(date);
</script>

<!----------------------------------------------------------------------------->

<template>
  <ul :class="{ white }">
    <li class="category">
      <span>{{ categoryTitle }}</span>
    </li>
    <li><Bullet small class="bullet" /></li>
    <li>
      <span>{{ formattedDate }}</span>
    </li>
  </ul>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  ul {
    user-select: none;

    @include industry-demi(px(12));
    color: $grey-3;

    display: flex;
    gap: px(10);

    li {
      display: flex;
      align-items: center;

      &.category {
        color: $red;
      }
    }

    &.white {
      li {
        color: $white;
      }
    }
  }
</style>
