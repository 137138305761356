<script setup>
  import gsap from 'gsap';

  const props = defineProps(['leaderboards']);
  let leaderboards = props.leaderboards;
  // TEMP
  leaderboards = leaderboards.slice(0, 6);

  const leaderboardIndex = ref(0);
  const leaderboard = ref(leaderboards[leaderboardIndex.value]);

  function onPrevClick() {
    leaderboardIndex.value = leaderboardIndex.value > 0 ? leaderboardIndex.value - 1 : leaderboards.length - 1;
    update(false);
  }

  function onNextClick() {
    leaderboardIndex.value = leaderboardIndex.value < leaderboards.length - 1 ? leaderboardIndex.value + 1 : 0;
    update(true);
  }

  watch(leaderboardIndex, () => {
    leaderboard.value = leaderboards[leaderboardIndex.value];
  });

  // --
  const numChampions = leaderboards.length;

  const champions = ref();
  const championData = ref(leaderboards);

  let championElements = [];

  const heading = ref();

  onMounted(() => {
    championData.value = [];
    leaderboards.forEach((board, index) => {
      championData.value.push({ ...board, position: index, index });
    });
  });

  watch(championData, () => {
    championElements = Array.from(champions.value.querySelectorAll('li'));

    resize();
  });

  function update(next) {
    championData.value.forEach((champion, index) => {
      champion.position = mod(index - leaderboardIndex.value, numChampions);
    });

    gsap.killTweensOf(championElements);
    championData.value.forEach((champion, index) => {
      const newPosition = left + champion.position * spacing - championElements[index].offsetWidth * offset;

      if ((!next && champion.position === 0) || (next && champion.position === numChampions - 1)) {
        gsap.to(championElements[index], {
          opacity: 0.0,
          duration: 0.08,
          ease: 'sine.out',
          onComplete: () => {
            championElements[index].style.zIndex = numChampions - champion.position;

            gsap.set(championElements[index], { x: newPosition });
            gsap.to(championElements[index], {
              opacity: 1.0,
              duration: 0.18,
              ease: 'sine.out',
            });
          },
        });
      } else {
        championElements[index].style.zIndex = numChampions - champion.position;

        gsap.to(championElements[index], {
          x: newPosition,
          opacity: 1.0,
          force3D: true,
          duration: 0.29,
          ease: 'quart.out',
        });
      }
    });
  }

  let spacing = 0.0;
  let left = 0.0;
  let offset = 0.0;

  onResize(() => {
    gsap.killTweensOf(championElements);
    resize();
  });

  function resize() {
    spacing = heading.value.offsetWidth / (numChampions + 0.5);
    left = (window.innerWidth - heading.value.offsetWidth) * 0.5;
    offset = window.innerWidth > 1024 ? 0.2 : 0.12;

    if (!championElements.length) return;

    championData.value.forEach((champion, index) => {
      championElements[index].style.zIndex = numChampions - champion.position;
      championElements[index].style.transform = `translate3d(${
        left + champion.position * spacing - championElements[index].offsetWidth * offset
      }px, 0.0, 0.0)`;
    });
  }

  // --

  const mod = (x, n) => ((x % n) + n) % n;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section
    autowrap
    wide
    :style="{ 'background-image': `url(${useCdn().generateStaticAsset('images/leaderboards_bg.png')})` }"
  >
    <BigTitle h1>
      <slot />
    </BigTitle>

    <ul ref="champions" class="champions">
      <li v-for="champion in championData" :class="{ active: champion.index === leaderboardIndex }">
        <!-- <img :src="champion.champion_image.url" /> -->
        <Picture preset="square-1024" :image="champion.champion_image" />
      </li>
    </ul>

    <GridItem :from="2" :to="11" class="boards">
      <Card color="gold">
        <div ref="heading" class="heading">
          <ArrowButton medium white left @click="onPrevClick" />
          <h2>{{ leaderboard.weight_class.title }}</h2>
          <ArrowButton medium white @click="onNextClick" />
        </div>
        <ul>
          <ShowAnimation class="animation-container" v-for="(fighter, key) in leaderboard.fighters">
            <li>
              <template v-if="fighter.fighter">
                <ClickableCard :to="fighter.fighter.url">
                  <span class="position" v-if="fighter.fighter.ranking.key === 'champion' || fighter.fighter.id === 83 && leaderboard.weight_class.slug === 'lightweight'"><IconChampion /></span>
                  <span class="position" v-else-if="fighter.fighter.ranking.key === 'interim'"
                    ><IconInterimChampion
                  /></span>
                  <span class="position" v-else>{{ fighter.ranking_overwrite ?? fighter.fighter.ranking.key }}.</span>
                  <span class="nationality-left">
                    <FighterThumbnail :fighter="useOverviewThumbnail(fighter.fighter)" class="photo" />
                    <FighterFlags :fighter="fighter" small />
                  </span>
                  <FighterLeaderboardChange :fighter="fighter" class="change-left" />
                  <h3>{{ fighter.fighter.title }}</h3>
                  <FighterFlags :fighter="fighter" class="nationality-right" />
                  <FighterLeaderboardChange :fighter="fighter" class="change-right" />
                </ClickableCard>
                <FighterThumbnail :fighter="fighter.fighter" class="hover" />
              </template>
              <div v-else :style="{ height: '81px' }" />
            </li>
          </ShowAnimation>
        </ul>
      </Card>
    </GridItem>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    background-color: $black-5;

    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;

    @include top-padding;
    padding-bottom: px(100);

    .champions {
      @include unwrap;

      height: 40vw;

      margin-top: -px(120);
      margin-bottom: -18.7vw;
      margin-left: -8vw;

      @include break-min-small {
        min-height: px(320);
      }

      li {
        position: absolute;
        width: 34vw;

        @include break-min-small {
          min-width: px(256);
        }

        transform-origin: center center;
        transform: translateX(-50%);

        img {
          object-fit: contain;
          object-position: center bottom;
        }

        &:not(.active) {
          filter: brightness(40%);
          animation: deactivate 0.21s $sineOut;
        }

        &.active {
          animation: activate 0.36s $sineOut;
          z-index: 4;

          mask-image: linear-gradient(180deg, $black 50%, transparent 100%);
          -webkit-mask-image: linear-gradient(180deg, $black 50%, transparent 100%);
        }

        &:not(.active) {
          mask-image: linear-gradient(180deg, $black 58%, transparent 80%);
          -webkit-mask-image: linear-gradient(180deg, $black 58%, transparent 80%);
        }

        @keyframes activate {
          0% {
            filter: brightness(40%);
            -webkit-filter: brightness(40%);
          }
          100% {
            filter: brightness(100%);
            -webkit-filter: brightness(100%);
          }
        }

        @keyframes deactivate {
          0% {
            filter: brightness(100%);
            -webkit-filter: brightness(100%);
          }
          100% {
            filter: brightness(40%);
            -webkit-filter: brightness(40%);
          }
        }
      }
    }

    .boards {
      position: relative;
      z-index: 10;

      user-select: none;

      .heading {
        width: 100%;
        height: px(151);

        padding: px(4) px(88) 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: px(16);

        h2 {
          @include gold-text;
          @include title-m;
          font-size: px(72);

          text-align: center;
        }

        a {
          flex-shrink: 0;
        }
      }

      > .card {
        background-color: $black;
        padding-bottom: px(64);
      }

      ul {
        .animation-container {
          position: relative;

          &:nth-child(odd) {
            background-color: $black-2;
          }

          &:nth-child(even) {
            background-color: $black-3;
          }

          @include hover {
            z-index: 10;
            li {
              background-color: $red;

              .hover {
                opacity: 1;
                @include fade('opacity', 0.28s);
              }
            }
          }
        }
        li {
          position: relative;
          border-bottom: px(1) solid $black-5;

          z-index: 1;

          .position {
            display: flex;
            justify-content: center;

            min-width: px(24);

            svg {
              margin-top: px(-3);
            }
          }

          @include title-xxs;
          padding: 0 px(100);

          a {
            display: flex;
            justify-content: stretch;
            align-items: center;

            gap: px(16);

            height: px(80);

            &:before {
              content: none;
            }
          }

          .change {
            &.change-right {
              display: none;
            }
          }

          h3 {
            @include title-xs;
            margin-right: auto;

            margin-top: px(4);
            padding-right: px(16);
          }

          .nationality-left {
            position: relative;
            display: none;

            .photo {
              width: px(52);
              height: px(62);
            }

            .flags {
              position: absolute;

              bottom: px(3);
              right: px(3);

              z-index: 2;
            }
          }

          .hover {
            position: absolute;

            width: px(224);
            height: px(242);

            top: 25%;
            left: 70%;

            transform: translate(-50%, -50%);

            pointer-events: none;

            opacity: 0;
            z-index: 1;

            @include fade('opacity', 0.22s);
          }
        }
      }
    }

    @include break-large {
      .champions {
        margin-left: -8vw;
      }

      .champions {
        margin-bottom: -20vw;
      }

      .boards {
        > .card {
          padding-bottom: px(48);
        }

        .heading {
          padding: px(4) px(48) 0;
          h2 {
            font-size: 6vw;
          }
        }

        ul {
          li {
            padding: 0 px(60);
          }
        }
      }
    }

    @include break-medium {
      padding-bottom: px(72);

      .champions {
        margin-left: -8vw;
      }

      .boards {
        @include column(1, 12);

        > .card {
          padding-bottom: px(32);
        }

        .heading {
          height: px(131);
          padding: px(6) px(24) 0;

          a {
            border: none;
          }
        }

        ul {
          li {
            padding: 0 px(32);

            h3 {
              font-size: px(28);
            }
          }
        }
      }
    }

    @include break-small {
      padding-bottom: px(64);

      .champions {
        margin-bottom: -px(170);
        margin-top: -20vw;
      }

      .boards {
        .heading {
          padding: px(6) px(12) 0;

          h2 {
            font-size: px(40);
          }
        }

        ul {
          li {
            padding: 0 px(20);
            height: px(84);

            .position {
              svg {
                width: px(14);
              }
            }

            h3 {
              font-size: px(24);
            }

            .change {
              font-size: px(11);

              &.change-left {
                display: none;
              }

              &.change-right {
                display: flex;
              }
            }

            .nationality-left {
              display: flex;
            }

            .nationality-right {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
