<script setup>
  const { firstSection } = defineProps(['firstSection']);

  // --

  const channelStore = useFastChannelsStore();
  await channelStore.fetch();

  const country = ref('-');
  const hasCountry = ref(false);
  const channels = ref([]);
  const countries = channelStore.getCountries.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

  // --

  function onCountrySelect(country) {
    channels.value = channelStore.getChannels(country.value);
  }

  // --

  onMounted(async () => {
    country.value = useGuessCountry();

    if (country.value !== '-') {
      onCountrySelect(country);
    }

    hasCountry.value = true;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide :class="{ firstSection }">
    <BigTitle>
      <slot />
    </BigTitle>
    <ShowAnimation>
      <Foreground class="container">
        <GridItem :from="2" :to="11" class="wrapper">
          <Card>
            <div>
              <h3>Select Country for fast channels</h3>
              <Select
                v-if="hasCountry"
                :items="countries"
                :initial="country"
                @select="onCountrySelect"
                class="select"
              />
            </div>
            <div class="channel-container" :class="{ 'has-no-results': !channels.length }">
              <FastChannelList v-if="channels.length" :channels="channels" class="channels" />
              <div v-else class="no-results">Fast channels are not available in the selected country</div>
            </div>
          </Card>
        </GridItem>
      </Foreground>
    </ShowAnimation>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-top: px(90);
    padding-bottom: px(128);
    margin-bottom: -$content-padding-bottom;

    background-color: $black;

    &.firstSection {
      @include top-padding;
    }

    .container {
      @include grid;
    }

    .wrapper {
    }

    .card {
      padding: px(74) 0 0;
      background-color: $black-2;

      @include break-min-large {
        min-height: px(400);
      }

      > div {
        padding: 0 px(108);

        &:last-child {
          border-top: px(1) solid $black-5;
        }
      }

      h3 {
        font-size: px(48);
        text-align: center;
      }

      .select {
        padding: px(26) 0 px(32);
      }

      .channel-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &.has-no-results {
          justify-content: center;
        }

        min-height: px(172);
      }

      .channels {
        margin-top: px(32);
        margin-bottom: px(32);
      }

      .no-results {
        color: $grey-1;
        @include heroic-bold(px(28));

        text-align: center;
        align-self: center;

        max-width: px(368);
      }
    }

    @include break-large {
      .card {
        padding: px(64) 0 0;

        > div {
          padding: 0 px(64);
        }

        h3 {
          font-size: px(42);
        }

        .select {
          padding-top: px(18);
        }
      }
    }

    @include break-medium {
      .card {
        padding: px(56) 0 0;

        > div {
          padding: 0 px(48);
        }

        h3 {
          font-size: px(32);
        }
      }
    }

    @include break-small {
      .wrapper {
        @include column(1, 12);
      }

      .card {
        > div {
          padding: 0 px(32);
        }

        h3 {
          font-size: px(24);
        }

        .select {
          padding-top: px(14);
        }

        .no-results {
          font-size: px(24);
          max-width: 280px;
        }
      }
    }

    @include break-phone {
      .card {
        > div {
          padding: 0 px(28);
        }

        .select {
          padding-top: px(12);
        }
      }
    }
  }
</style>
