<script setup>
  const { item } = defineProps(['item']);

  const percentage = item.data.value / item.data.total;
  const percentageString = (percentage * 100.0).toFixed(2);
</script>

<!----------------------------------------------------------------------------->

<template>
  <FighterStatCard :title="item.title" :subtitle="item.subtitle"
    ><FighterStatCircle :percentage="percentage * 100.0"
      ><h5>{{ percentageString }}</h5></FighterStatCircle
    >
  </FighterStatCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .stat {
    h5 {
      font-size: px(48);
    }
  }
</style>
