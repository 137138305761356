<script setup>
  const { small, resize } = defineProps({ small: Boolean, resize: Function });

  // --

  const container = ref();

  const whiteCorner = ref();
  const vs = ref();
  const blackCorner = ref();

  let maxFontSize = 0.0;

  // --

  onMounted(() => {
    maxFontSize = parseFloat(getComputedStyle(container.value, null).getPropertyValue('font-size'));
  });

  onUpdated(() => {
    handleResize(window.innerWidth);
  });

  // --

  onResize(handleResize);
  function handleResize(windowWidth) {
    const sweet16offset = Math.max(windowWidth / 1600.0, 1.0);

    const containerWidth = container.value.parentElement.getBoundingClientRect().width;

    const whiteCornerWidth = whiteCorner.value.getBoundingClientRect().width;
    const vsWidth = vs.value.getBoundingClientRect().width;
    const blackCornerWidth = blackCorner.value.getBoundingClientRect().width;

    const fontSize = parseFloat(getComputedStyle(container.value, null).getPropertyValue('font-size'));

    // --
    container.value.style.fontSize = `${Math.min(
      resize(containerWidth, whiteCornerWidth, vsWidth, blackCornerWidth, fontSize),
      sweet16offset * maxFontSize,
    )}px`;
  }
</script>

<template>
  <span ref="container" class="container" :class="{ small }">
    <span class="white-corner" ref="whiteCorner"><slot name="whiteCorner" /></span>
    <span ref="vs" class="vs"><span>vs</span></span>
    <span class="black-corner" ref="blackCorner"><slot name="blackCorner" /></span>
  </span>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    position: relative;
    white-space: nowrap;

    display: inline-flex;
    align-items: stretch;

    @include heroic-heavy(px(264));

    .vs {
      @include heroic-bold(px(48));

      display: flex;
      align-items: center;

      span {
        margin-top: 0.17em;
      }

      &:before,
      &:after {
        content: '';
        background-color: currentColor;
      }
    }

    @include break-small {
      .white-corner {
        margin-bottom: 0.02em;
      }
    }

    @include break-phone {
      line-height: 0.8; // override typography line height

      .white-corner {
        margin-bottom: 0.06em;
      }

      .vs {
        line-height: 0.8; // override typography line height
      }
    }
  }
</style>
