<script setup>
  import FighterStatDifference from '~/components/parts/stats/FighterStatDifference.vue';
  import FighterStatNumber from '~/components/parts/stats/FighterStatNumber.vue';
  import FighterStatPercentage from '~/components/parts/stats/FighterStatPercentage.vue';
  import FighterStatRatio from '~/components/parts/stats/FighterStatRatio.vue';
  import FighterStatRecord from '~/components/parts/stats/FighterStatRecord.vue';
  import FighterStatTime from '~/components/parts/stats/FighterStatTime.vue';

  const { item } = defineProps(['item']);

  const stats = {
    difference: FighterStatDifference,
    number: FighterStatNumber,
    percentage: FighterStatPercentage,
    ratio: FighterStatRatio,
    record: FighterStatRecord,
    time: FighterStatTime,
  };
</script>

<!----------------------------------------------------------------------------->

<template><component :is="stats[item.type]" :item="item" /></template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
