<script setup>
  import VLazyImage from 'v-lazy-image';

  const { image, preset, notLazy } = defineProps(['image', 'preset', 'notLazy']);

  let absolute = true;
  let imageData;

  if (typeof image !== 'string' && image !== null) {
    imageData = useCdn().generatePicture(image, preset);
    absolute = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <picture v-if="image">
    <img v-if="absolute" :src="image" />
    <img
      v-else-if="notLazy"
      :src="imageData.imageUrl"
      :width="imageData.width"
      :height="imageData.height"
      :alt="imageData.alt"
    />
    <v-lazy-image
      v-else
      :src="imageData.imageUrl"
      :src-placeholder="imageData.placeholderUrl"
      :width="imageData.width"
      :height="imageData.height"
      :alt="imageData.alt"
    />
  </picture>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  picture {
    display: flex;

    width: 100%;
    height: 100%;
  }

  :deep(img) {
    width: 100%;
    height: auto;

    object-fit: cover;
  }
</style>
