<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  useBackToTop().backToTopActive.value = false;
</script>

<!----------------------------------------------------------------------------->

<template>
  <GirlsHero :girls="page.girls">{{ page.title }}</GirlsHero>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
