export default (callback) => {
  onMounted(async () => {
    window.onmessage = async function (e) {
      if (e.data.name === 'statamic.preview.updated') {
        useApi().setPreviewToken(useRoute().query.token);
        callback();
      }
    };
  });
};
