<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <div><IconPlay /></div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: px(120);
    height: px(120);

    border-radius: px(60);
    background-color: $black;

    svg {
      margin-left: 4%;

      width: 20%;
      height: 20%;
    }

    @include break-large {
      width: 9vw;
      height: 9vw;
    }
  }
</style>
