<script setup>
  const { item, index } = defineProps(['item', 'index', 'active']);
  const { title } = item;

  // --
  const emit = defineEmits(['select']);
</script>

<!----------------------------------------------------------------------------->

<template>
  <button @click="emit('select', index)" :class="{ active }">{{ title }}</button>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  button {
    padding: px(16);
    font-size: px(16);

    width: 100%;
    text-align: left;

    color: $black;

    &.active {
      font-weight: 700;
    }

    @include fade('background-color', 0.28s);
    @include hover {
      background-color: #eaeaea;
      @include fade('background-color', 0.17s);
    }
  }
</style>
