import { defineStore } from 'pinia';
import { statamicRequest } from '~/pinkyellow/satanic-api';

export const useFighterNewsStore = defineStore('fighter-news', {
  state: () => ({
    news: [],
  }),

  getters: {
    getNews(state) {
      return state.news;
    },
  },

  actions: {
    async fetch(fighter) {
      const news = await statamicRequest({ uri: 'fighter/news/' + fighter });

      if (!news) {
        this.news = [];
        return;
      }

      this.news = news.news;
    },
  },
});
