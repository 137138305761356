<script setup>
  import WatchTitleFight from '~/components/parts/watch/WatchTitleFight.vue';
  import WatchTournament from '~/components/parts/watch/WatchTournament.vue';
  import WatchTournamentTitleFight from '~/components/parts/watch/WatchTournamentTitleFight.vue';

  // --
  const { events, showSoldOut } = defineProps(['events', 'showSoldOut']);

  // --
  const cards = {
    title_fight: WatchTitleFight,
    tournament: WatchTournament,
    tournament_title: WatchTournamentTitleFight,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide>
    <BigTitle h1>
      <slot />
    </BigTitle>
    <Foreground>
      <div class="events">
        <component
          v-for="event in events"
          :is="cards[event.event.event_type.value]"
          :event="event.event"
          :buttons="event.buttons"
          :showSoldOut="showSoldOut"
        />
      </div>
    </Foreground>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    @include top-padding;
    padding-bottom: px(100);

    .events {
      display: flex;
      flex-direction: column;

      gap: px(48);
    }

    @include break-medium {
      .events {
        gap: $margin-medium;
      }
    }

    @include break-small {
      .events {
        gap: $margin-small;
      }
    }

    @include break-phone {
      .events {
        gap: px(32);
      }
    }
  }
</style>
