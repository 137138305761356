<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('light');

  useBackToTop().backToTopActive.value = page.content.length > 1;
</script>

<!----------------------------------------------------------------------------->

<template>
  <PageHeading>{{ page.title }}</PageHeading>
  <BlockGenerator :content="page.content"></BlockGenerator>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
