<script setup>
  const { data, event } = defineProps(['data', 'event']);

  // --

  const tabs = data.rounds;
  const selectedTab = ref(tabs[0]);

  function onTabClick(tab) {
    selectedTab.value = tab;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <EventDetailCard class="container">
    <h3>{{ event.size.label }} {{ event.tournament_title }}</h3>
    <ul class="tabs">
      <li v-for="tab in tabs">
        <button :class="{ active: selectedTab.title === tab.title }" @click="() => onTabClick(tab)">
          {{ tab.title }}
        </button>
      </li>
    </ul>
    <ul v-if="selectedTab.type === 'fighters'" class="fighters">
      <li v-for="fighter in event.tournament_fighters">
        <ClickableCard :to="fighter?.url">
          <FighterThumbnail :fighter="fighter" />
          <h4>{{ fighter?.last_name }}</h4>
        </ClickableCard>
      </li>
    </ul>
    <div v-else class="cards">
      <EventDetailFight v-for="fight in selectedTab.fights" :card="fight" :key="fight.id" inTournament />
    </div>
  </EventDetailCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    @include break-min-small {
      padding-top: px(88);
    }

    h3 {
      @include gold-text;
      font-size: px(48);

      margin-bottom: px(44);

      text-align: center;

      @include break-small {
        font-size: px(32);

        margin-bottom: px(24);
        padding-left: px(32);
        padding-right: px(32);
      }
    }

    .fighters {
      display: flex;
      flex-wrap: wrap;

      row-gap: px(32);
      padding-bottom: px(64);

      li {
        width: 25%;
        border: px(1) solid $black-5;

        // left items
        &:not(:nth-child(4n + 1)) {
          border-left: none;
        }

        h4 {
          text-align: center;
          @include title-s;
          font-size: 3vw;

          padding: px(17) 0 px(9);

          border-top: px(1) solid $black-5;

          z-index: 1;
          background-color: $black;
          position: relative;
        }
      }

      @include break-small {
        border-top: px(1) solid $black-5;
        padding-bottom: 0;

        li {
          width: 50%;
          border-top: none;
          border-left: none;

          h4 {
            font-size: px(32);
            padding: px(20) 0 px(15);
          }

          // right items
          &:nth-child(2n) {
            border-right: none;

            h4 {
              margin-top: -1px;
            }
          }
        }
        row-gap: 0;
      }
    }

    .cards {
      display: flex;
      flex-direction: column;

      gap: px(32);

      padding-bottom: px(64);

      @include break-small {
        padding-bottom: 0;
        gap: 0;
      }
    }

    .tabs {
      border-top: px(1) solid $black-5;

      padding: px(26) 0 px(32);

      display: flex;
      justify-content: center;
      gap: px(80);

      li {
        button {
          @include industry-demi(px(16));
          color: $grey-1;

          @include hitbox;
          line-height: 1.6;

          @include fade('color', 0.37s);
          @include hover {
            @include fade('color', 0.15s);
            color: $white;
          }

          &.active {
            color: $white;
            border-bottom: px(2) solid $white;
          }
        }
      }

      @include break-large {
        gap: 4vw;

        li {
          button {
            font-size: px(14);
          }
        }
      }

      @include break-small {
        gap: 5vw;

        li {
          button {
            font-size: px(12);
          }
        }
      }
    }
  }
</style>
1
