<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  useBackToTop().backToTopActive.value = page.events.length > 1;
</script>

<!----------------------------------------------------------------------------->

<template>
  <WatchHero :events="page.events" showSoldOut>{{ page.title }}</WatchHero>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
