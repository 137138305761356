<script setup>
  const { event } = defineProps(['event']);

  const longAgo = useDate().longAgo(event.ends_at);
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="bar" :class="{ longAgo }">
    <EventBarClock class="clock" :event="event" />
    <EventBarInfo :event="event" />
    <EventBarSponsors class="sponsors" :sponsors="event.sponsors" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .bar {
    overflow: hidden;

    width: 100%;
    height: px(80);

    border: px(1) solid $grey-1;
    border-radius: px(5);

    display: flex;

    div {
      &:not(:last-child) {
        @include break-min-small {
          border-right: px(1) solid $grey-1;
        }
      }
    }

    .info {
      width: 30%;
      flex-shrink: 0;
    }

    &.longAgo {
      @include break-small {
        border: 0;
        height: auto;
        margin-top: 2rem;
      }
      .info {
        width: auto;
      }
    }

    .sponsors {
      width: 100%;
    }

    @include break-medium {
      height: px(72);
    }

    @include break-small {
      height: px(64);

      .info {
        display: none;
      }

      .sponsors {
        display: none;
      }
    }

    @include break-small {
      width: auto;
    }
  }
</style>
