<script setup>
  const { data } = defineProps(['data']);
  const { quote } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <blockquote><span>“</span>{{ quote }}”</blockquote>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  blockquote {
    position: relative;
    margin: 0;

    span {
      display: none;
    }

    &:before {
      content: '“';
      position: absolute;

      top: 0;
      left: 0;

      margin-left: -0.35em;
    }

    @include roboto-regular(px(40));
    line-height: 1.4;

    @include break-large {
      font-size: px(32);
    }

    @include break-medium {
      font-size: px(28);
    }

    @include break-small {
      font-size: px(24);
    }

    @include break-phone {
      text-align: center;

      span {
        display: inline;
      }

      &:before {
        display: none;
      }
    }
  }

  .column {
    @include break-min-small {
      &.left {
        @include column(2, 5);
      }

      &.right {
        @include column(8, 11);
      }
    }

    @include break-small {
      @include column(2, 11);
    }

    @include break-phone {
      @include column(1, 12);
    }
  }
</style>
