<script setup>
  import gsap from 'gsap';

  const { newsletterOverlayActive } = useNewsletterOverlay();

  // --
  const { data } = defineProps({ data: Object, home: Boolean });
  const {
    newsletter_promo_title,
    newsletter_promo_subtitle,
    newsletter_promo_button,
    newsletter_promo_video,
    newsletter_promo_sponsors,
  } = data;

  const sponsor = ref(newsletter_promo_sponsors[0]);
  const sponsorLength = newsletter_promo_sponsors.length;

  const sponsorRef = ref();

  let sponsorIndex = 0;

  // --

  let interval;
  onMounted(() => {
    interval = setInterval(() => {
      sponsorIndex = sponsorIndex < sponsorLength - 1 ? sponsorIndex + 1 : 0;

      gsap.killTweensOf(sponsorRef.value);
      gsap.to(sponsorRef.value, {
        opacity: 0.0,
        duration: 0.35,
        ease: 'sine.in',
        onComplete: () => {
          sponsor.value = newsletter_promo_sponsors[sponsorIndex];
          gsap.to(sponsorRef.value, { opacity: 1.0, duration: 0.32, delay: 0.03, ease: 'sine.out' });
        },
      });
    }, 1750.0);
  });

  onBeforeUnmount(() => {
    gsap.killTweensOf(sponsorRef.value);
    clearInterval(interval);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap full :class="{ home }">
    <ShowAnimation fadeOnly class="animation-container">
      <GridItem :from="2" :to="6" class="left">
        <h2>{{ newsletter_promo_title }}</h2>
        <p v-if="newsletter_promo_subtitle">{{ newsletter_promo_subtitle }}</p>
        <Button color="black" @click="newsletterOverlayActive = true" to="javascript:;">{{
          newsletter_promo_button
        }}</Button>
        <!--      <Button to='@triggerNewsletterPopup' color='black'>{{ newsletter_promo_button }}</Button>-->
      </GridItem>
      <GridItem :from="7" :to="11" class="right">
        <img ref="sponsorRef" :src="sponsor.logo.url" :alt="sponsor.name" width="285" height="67" />
      </GridItem>
      <!-- <div class="background" :style="{ 'background-image': `url(${image.url})` }" /> -->

      <VideoBackground v-if="newsletter_promo_video" :src="newsletter_promo_video.url" class="background" />
      <div class="gradient" />
    </ShowAnimation>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    position: relative;
    color: $white;
    background-color: $white;

    ::selection {
      background-color: $white;
      color: $black;
    }

    height: px(624);
    display: flex;

    .animation-container {
      @include grid;
      align-items: center;
      height: 100%;
    }

    &.home {
      padding-top: 0;
      padding-bottom: px(128);

      height: px(624 + 64); // offset additional padding
    }

    @include break-small {
      height: 120vw;

      &.home {
        padding-bottom: px(64);
        height: calc(120vw); // offset additional padding
      }
    }
    @include break-phone {
      height: px(896);

      &.home {
        padding-bottom: 0;
        height: px(896 - 128); // offset padding
      }
    }
  }

  :deep(.grid) {
    position: relative;
    align-items: center;

    height: 100%;

    .left {
      z-index: $layer-foreground;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      gap: px(16);

      a {
        @include hover {
          background-color: $white;
          color: $black;
        }
      }
    }

    .right {
      z-index: $layer-foreground;

      display: flex;
      justify-content: center;

      img {
        width: 20vw;
      }
    }

    @include break-large {
      .right {
        img {
          width: 25vw;
        }
      }
    }

    @include break-small {
      .left {
        @include column(2, 11);
        padding-bottom: 10vw;

        align-self: flex-end;

        gap: px(8);

        a {
          margin-top: px(20);
        }
      }

      .right {
        @include column(1, 12);
        grid-row: 1;

        img {
          width: 70vw;
          max-width: px(200);

          margin-top: px(55);
        }
      }
    }

    @include break-phone {
      .left {
        @include column(1, 12);
        padding-bottom: 11vw;

        padding-left: $margin-phone;
        padding-right: $margin-phone;

        h2 {
          font-size: px(80);
        }
      }
    }
  }

  .background {
    @include break-small {
      height: 65%;
    }

    @include break-phone {
      height: 50%;
    }
  }

  .gradient {
    position: absolute;

    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: center;

    &:after {
      position: absolute;
      content: '';

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;

      background: linear-gradient(90deg, rgba(195, 0, 0, 1) 20%, rgba(195, 0, 0, 0) 60%);

      @include break-small {
        background: linear-gradient(0, rgba(195, 0, 0, 1) 48%, rgba(195, 0, 0, 0) 62%);
      }

      @include break-phone {
        background: linear-gradient(0, rgba(195, 0, 0, 1) 58%, rgba(195, 0, 0, 0) 72%);
      }
    }

    @include break-phone {
      left: 0;
      right: 0;
    }
  }
</style>
