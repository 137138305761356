<script setup>
  const { data, no_margin } = defineProps(['data', 'no_margin']);
  const { button_title, button_link } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <div>
    <Button v-if="button_link && button_title" :to="button_link" :class="{ no_margin }">{{ button_title }}</Button>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    margin-top: px(64);

    &.no_margin {
      margin-top: 0;
    }
  }

  .column {
    @include break-min-small {
      &.left {
        @include column(2, 5);
      }

      &.right {
        @include column(8, 11);
      }
    }

    @include break-small {
      @include column(2, 11);
    }

    @include break-phone {
      @include column(1, 12);
    }
  }
</style>
