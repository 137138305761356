<template>
  <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 23.0657C0.5 23.4651 0.945073 23.7033 1.27735 23.4818L17.876 12.416C18.1728 12.2181 18.1728 11.7819 17.876 11.584L1.27735 0.518234C0.945074 0.296716 0.5 0.534911 0.5 0.934259V23.0657Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(19);
  }
</style>
