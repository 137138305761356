<script setup>
  const { data } = defineProps(['data']);
  const { image } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <ShowAnimation fadeOnly>
    <figure>
      <Picture preset="landscape-small" :image="image" /></figure
  ></ShowAnimation>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .column {
    &.left {
      @include column(1, 6);
      @include unwrap-left;

      @include break-small {
      }
    }

    &.right {
      @include column(7, 12);
      @include unwrap-right;
    }

    @include break-small {
      margin-bottom: $block-padding-large;
      grid-row: 1;

      &.left {
        @include column(1, 9);
      }

      &.right {
        @include column(4, 12);
      }
    }

    @include break-phone {
      margin-bottom: $block-padding-medium;
      &.left {
        @include column(1, 10);
      }

      &.right {
        @include column(3, 12);
      }
    }
  }
</style>
