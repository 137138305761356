<script setup>
  const { fighter } = defineProps(['fighter']);
  const weightClass = useTaxonomyStore().getTerm('weight_classes', fighter.weight_class);
  const ranking = useFighterRanking(fighter.ranking, weightClass);

  const mainIso = ref(null);
  if (fighter.nationality[0]) {
    mainIso.value = fighter.nationality[0].key.toLowerCase();
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap>
    <div class="container">
      <h2 v-if="fighter.id === 83">Lightweight Champion</h2>
      <h2>{{ fighter.id === 83 ? 'Welterweight ' : ''}}{{ ranking }}</h2>
      <h1>{{ fighter.first_name }} {{ fighter.last_name }}</h1>
      <div v-if="mainIso" class="fighter">
        <FighterFlag :iso="mainIso" />
        <showAnimation class="animation-container" fadeOnly>
          <img
            class="front"
            v-if="fighter.front_image"
            :src="fighter.front_image.url"
            :alt="fighter.title"
            width="640"
            height="640"
          />
          <!-- <Picture v-if="fighter.front_image" preset="square-1024" :image="fighter.front_image" class="front" /> -->
        </showAnimation>
      </div>
    </div>
    <img :src="useCdn().generateStaticAsset('images/fighter_hero_bg.png')" :alt="fighter.title" class="spotlights" />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-top: 0;
    padding-bottom: 8vw;

    margin-bottom: -2vw;

    background-color: $black-3;

    overflow: hidden;

    .spotlights {
      // justify-self: center;
      position: absolute;
      transform: translate(-50%, 0);

      left: 50%;

      width: 100vw;
      height: auto;

      z-index: 1;
    }

    &:after {
      content: '';

      position: absolute;
      left: 0;
      bottom: 0;

      z-index: 1001;

      width: 100%;
      height: 16vw;

      background: linear-gradient(0deg, $black-3 32%, rgba(195, 0, 0, 0) 100%);
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      gap: px(2);

      @include top-padding;
    }

    h1 {
      font-size: 30vw;
      z-index: 2;
    }

    h2 {
      @include heroic-bold(4.2vw);
      @include gold-text;

      z-index: 2;
      &:last-of-type {
        margin-bottom: 3.6vw;
      }
    }

    .fighter {
      position: relative;
      margin-top: -34vw;
    }

    .animation-container {
      position: relative;
      z-index: 10;
    }

    .front {
      position: absolute;
      left: 50%;
      height: auto;

      z-index: 3;

      transform: translate(-50%, -60%);

      width: 46vw;
      margin-top: -10vw;
    }

    @include break-large {
      .front {
        // width: 46vw;
      }
    }

    @include break-medium {
      .front {
        // width: 60vw;
      }
    }

    @include break-small {
      padding-bottom: 32vw;

      h2 {
        font-size: px(32);
        margin-bottom: px(30);
      }

      .spotlights {
        width: 180%;
      }

      .fighter {
        margin-top: -42vw;
      }

      &:after {
        background: linear-gradient(0deg, $black-3 10%, rgba(195, 0, 0, 0) 100%);
      }

      .front {
        width: 120vw;
      }

      @include break-phone {
      }
    }
  }
</style>
