<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <EventHero :event="page" />
  <HowToWatch v-if="page.how_to_watch" :event="page" id="how-to-watch">How To Watch</HowToWatch>
  <EventDetails :event="page" />
  <RelatedNews v-if="page.news.length" :items="page.news" black>Related news</RelatedNews>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
