<script setup>
  const { data } = defineProps(['data']);
  const { title, questions } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap>
    <WrapCenter>
      <ShowAnimation
        ><h2>{{ title }}</h2></ShowAnimation
      >
      <FaqQuestion v-for="({ question, body }, index) in questions" :question="question" :body="body" :key="index" />
    </WrapCenter>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-bottom: px(48);
  }

  h2 {
    font-size: px(64);
    padding-bottom: px(7);

    @include break-small {
      font-size: px(56);
    }

    @include break-phone {
      font-size: px(40);
    }
  }
</style>
