<script setup>
  import UpcomingEventTitleFight from '~/components/parts/events/UpcomingEventTitleFight.vue';
  import UpcomingEventTournament from '~/components/parts/events/UpcomingEventTournament.vue';
  import UpcomingEventTournamentTitleFight from '~/components/parts/events/UpcomingEventTournamentTitleFight.vue';

  // --
  const { events } = defineProps(['events']);

  // --
  const cards = {
    title_fight: UpcomingEventTitleFight,
    tournament: UpcomingEventTournament,
    tournament_title: UpcomingEventTournamentTitleFight,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section autowrap wide>
    <BigTitle h1>
      <slot />
    </BigTitle>
    <Foreground>
      <div class="events">
        <component v-for="event in events" :is="cards[event.event_type.value]" :event="event" />
      </div>
    </Foreground>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  // :deep(h1) {
  //   z-index: $layer-foreground;
  // }

  section {
    @include top-padding;
    padding-bottom: px(100);

    .events {
      display: flex;
      flex-direction: column;

      gap: px(48);
    }

    @include break-medium {
      .events {
        gap: $margin-medium;
      }
    }

    @include break-small {
      .events {
        gap: $margin-small;
      }
    }

    @include break-phone {
      .events {
        gap: 0;
      }
    }
  }
</style>
