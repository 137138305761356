<script setup>
  import ImageLandscapeContent from '~/components/parts/content/ImageLandscape.vue';
  import ImagePortraitContent from '~/components/parts/content/ImagePortrait.vue';
  import QuoteContent from '~/components/parts/content/Quote.vue';
  import TextCtaContent from '~/components/parts/content/TextCta.vue';
  import GeneratorError from '~/components/generators/GeneratorError.vue';

  // --

  const { content, side } = defineProps(['content', 'side']);

  const columns = {
    image_landscape: ImageLandscapeContent,
    image_portrait: ImagePortraitContent,
    quote: QuoteContent,
    text_cta: TextCtaContent,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <component
    v-for="column in content"
    :key="column.id"
    :is="columns[column.type] || GeneratorError"
    :data="column"
    :error="`column ${column.type} not found`"
    class="column"
    :class="side"
  />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
