<script setup>
  const { page } = defineProps(['page']);
  const asset = { image: page.cover_image };

  useTheme().setTheme('light');

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <NewsHeading :item="page">{{ page.title }}</NewsHeading>
  <ImageBlock :data="asset" />
  <BlockGenerator :content="page.content"></BlockGenerator>
  <RelatedNews v-if="page.news.length" :items="page.news" reducedBottomPadding>Related news</RelatedNews>
  <Back v-if="page.news.length">Back to news</Back>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
