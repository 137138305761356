<script setup>
  const { page } = defineProps(['page']);

  useTheme().setTheme('dark');

  useBackToTop().backToTopActive.value = true;
</script>

<!----------------------------------------------------------------------------->

<template>
  <FighterLeaderboards :leaderboards="page.leaderboards">{{ page.title }}</FighterLeaderboards>
  <AllFighters />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
